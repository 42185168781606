<template>
  <div class="product-order">
    <div class="store">
      <img class="logo" :src="`${data.businessLogo}?x-oss-process=style/thumb_600`" />
      <div class="name">{{ data.businessName || data.businessTitle }}</div>
      <template v-if="!orderSubmit">
        <div class="status" v-if="stat">{{ status[data.orderShowStatus] }}</div>
      </template>
    </div>
    <div
      class="product"
      @click.stop="$router.push(`/product/${data.productId}/detail?source=${8}`)"
    >
      <div class="img-box">
        {{ data.packSku }}
        <img
          class="img"
          :src="
            data.productImageUrl ||
            `${data.skuPic || data.skuImageUrl}?x-oss-process=style/thumb_600`
          "
          mode="aspectFill"
        />
      </div>
      <div class="right">
        <template v-if="formOrder">
          <div class="title">{{ data.productName }}</div>
          <div class="subtitle">{{ data.skuName }}</div>
          <div class="productTags">
            <div class="tagName" v-if="data.refundType == 1">不可退</div>
            <div class="tagName" v-if="data.isProductUse == 0">今日不可用</div>
          </div>
        </template>
        <template v-else>
          <div class="title">{{ data.productName }}</div>
          <div class="subtitle" v-if="!data.isGift">{{ data.skuName }}</div>
          <div class="productTags">
            <div class="tagName" v-if="data.refundType == 1">不可退</div>
            <div class="tagName" v-if="data.isProductUse == 0">今日不可用</div>
          </div>
        </template>
        <div class="writeOffTimeEnd" v-if="data.orderShowStatus == 1">
          有效期至：{{ formatFullDate(data.writeOffTimeEnd) }}
        </div>
        <div class="price">
          <div class="amount" v-if="data.skuTotalNum">
            x {{ data.isGift ? data.giftNum : data.skuTotalNum }}
          </div>
          <div v-if="formOrder">
            实付￥{{ data.actualPayMoney || data.payMoney || data.skuTotalMoney }}
          </div>
          <div v-else>
            ￥{{
              data.isGift
                ? data.giftMoney
                : data.salePrice || data.price || data.skuTotalMoney || '--'
            }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { formatFullDate } from '@/util/filter'
</script>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: {}
    },
    amount: Boolean, //显示数量
    stat: {
      //是否显示订单状态
      type: Boolean,
      default: true
    },
    formOrder: {
      type: Boolean,
      default: true
    },
    orderSubmit: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      status: {
        0: '待支付',
        1: '待核销',
        2: '已核销',
        3: '已过期',
        4: '已关闭',
        11: '退款中',
        12: '已退款',
        21: '已评价'
      }
    }
  }
}
</script>

<style lang="less" scoped>
.product-order {
  overflow: hidden;
  .store {
    overflow: hidden;
    display: flex;
    align-items: center;
    .logo {
      width: 35px;
      height: 35px;
      border-radius: 50%;
      overflow: hidden;
      object-fit: cover;
    }
    .name {
      flex: 1;
      font-size: 15px;
      color: #333333;
      margin-left: 5px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-weight: bold;
    }
    .status {
      font-size: 15px;
      color: #ed4c50;
      margin-left: 10px;
    }
  }
  .product {
    display: flex;
    margin-top: 10px;
    background-color: #fff;
    // border-radius: 16rpx;
    .img-box {
      width: 104px;
      height: 104px;
      border-radius: 5px;
      overflow: hidden;
      margin-right: 10px;
      background: lightgrey;
      .img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .right {
      flex: 1;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      overflow: hidden;
      padding: 5px 0;
      .title {
        font-size: 15px;
        color: #343434;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        word-break: break-all;
        display: -webkit-box;
        text-overflow: ellipsis;
      }
      .productTags {
        padding: 10px 0;
        display: flex;
        .tagName {
          margin-right: 10px;
          font-size: 12px;
          padding: 0 5px;
          height: 20px;
          text-align: center;
          line-height: 20px;
          border-radius: 4px;
          opacity: 0.8;
          border: 1px solid #ff3023;
          color: #e7141a;
          font-weight: 600;
        }
      }
      .subtitle {
        font-size: 14px;
        color: #9a9a9a;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .price {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 15px;
        color: #343434;
        font-weight: bold;
        .amount {
          font-size: 15px;
          color: #9a9a9a;
          font-weight: normal;
          // float: right;
        }
        .num{
          color: #e7141a;
        }
      }
    }
  }
}
.writeOffTimeEnd {
  padding-top: 2px;
  font-size: 12px;
  color: #ff9330;
}
</style>
