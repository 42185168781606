import * as order from './order'
import * as business from './business'
import * as user from './user'
import * as admin from './admin'
import * as bh from './businessHelper'
import * as statistics from './statistics'
import * as search from './search'
import * as movie from './movie'
import * as lucky from './luckyDraw'
import * as decorate from './decorate'

export default {
  order,
  business,
  user,
  admin,
  bh,
  statistics,
  search,
  movie,
  lucky,
  decorate
}
