import http from '@/plugin/axios'
import $store from '@/plugin/store'

let cacheList = []
let startTime = ''
let isLock = false

export default {
  uv(page) {
    if (page) {
      if (!cacheList.find(i => i.type === 'uv' && i.page === page)) {
        cacheList.push({ type: 'uv', page, time: Date.now() })
      }
      this.handleAfter()
    }
  },
  pv(page) {
    if (page) {
      if (!cacheList.find(i => i.type === 'pv' && i.page === page)) {
        cacheList.push({ type: 'pv', page, count: 1 })
      } else {
        cacheList.find(i => i.type === 'pv' && i.page === page).count++
      }
      this.handleAfter()
    }
  },
  click(page) {
    if (page) {
      if (!cacheList.find(i => i.type === 'click' && i.page === page)) {
        cacheList.push({ type: 'click', page, count: 1 })
      } else {
        cacheList.find(i => i.type === 'click' && i.page === page).count++
      }
      this.handleAfter()
    }
  },
  shopDetail(payloadId) {
    if (payloadId) {
      if (!cacheList.find(i => i.type === 'pv' && i.page === 'shopDetail' && i.payloadId === payloadId)) {
        cacheList.push({ type: 'pv', page: 'shopDetail', payloadId, count: 1 })
      } else {
        cacheList.find(i => i.type === 'pv' && i.page === 'shopDetail' && i.payloadId === payloadId).count++
      }
      this.handleAfter()
    }
  },
  storeDetail(payloadId) {
    if (payloadId) {
      if (!cacheList.find(i => i.type === 'pv' && i.page === 'storeDetail' && i.payloadId === payloadId)) {
        cacheList.push({
          type: 'pv',
          page: 'storeDetail',
          payloadId,
          count: 1
        })
      } else {
        cacheList.find(i => i.type === 'pv' && i.page === 'storeDetail' && i.payloadId === payloadId).count++
      }
      this.handleAfter()
    }
  },
  productDetail(payloadId) {
    if (payloadId) {
      if (!cacheList.find(i => i.type === 'pv' && i.page === 'productDetail' && i.payloadId === payloadId)) {
        cacheList.push({
          type: 'pv',
          page: 'productDetail',
          payloadId,
          count: 1
        })
      } else {
        cacheList.find(i => i.type === 'pv' && i.page === 'productDetail' && i.payloadId === payloadId).count++
      }
      this.handleAfter()
    }
  },
  handleAfter() {
    if (isLock) return
    console.log('>>>>>>> add new track', cacheList.length)
    if (!startTime) {
      startTime = Date.now()
    }
    if (cacheList.length >= 1) {
      this.handleSubmit()
    } else if (Date.now() - startTime >= 1 * 60 * 1000) {
      this.handleSubmit()
    }
  },
  handleSubmit() {
    http
      .post('/boom-center-statistics-service/trace/submit/v2', {
        traceStrList: cacheList
      })
      .then(() => {
        cacheList = []
        startTime = ''
        isLock = false
      })
      .catch(() => {
        isLock = false
      })
  },
  submitTrack(_params) {
    let params = {
      platform: 2,
      // channel: navigator.userAgent || sessionStorage?.channel || 'h5',
      channel: sessionStorage?.channel || 'h5',
      member_id: $store.getters['common/account/userId'] || '0',
      version: sessionStorage['version'],
      tenant_id: $store.getters['common/sys/currentTenantId'],
      area_id: $store.getters['common/sys/currentAreaId'],
      session_id: sessionStorage['sessionId'],
      channel_code_id: sessionStorage['channelCodeId'] || '0',
      session_time: new Date().getTime(),
      ..._params
    }

    try {
      http.post('/boom-center-statistics-service/trace/buriedPoint', params)
    } catch {}

    console.log(params)
  }
}
