import http from '@/plugin/axios'

const movieService = 'boom-center-movie-service/app/'
const searchService = 'boom-center-search-service/app/'
const mixService = 'boom-mix-biz-service/app/'
const orderService = 'boom-center-order-service/app/'
const adminService = 'boom-center-admin-service/'
//热门列表
export function hotList(page = 1, size = 10) {
  return http.get(movieService + 'movie/listHotPage', { page, size })
}

//即将上映列表
export function upcomingList(page = 1, size = 10) {
  return http.get(movieService + 'movie/listFuturePage', { page, size })
}

//电影详情
export function movieDetail(id) {
  return http.get(movieService + 'movie/' + id)
}

//影院列表
export function cinemaList(params) {
  return http.get(searchService + 'movieCinemas/page', params)
}

//根据电影查询影院列表
export function cinemaListByMovie(params) {
  return http.get(movieService + 'movie/selectListCinemasPageByMovieId', params)
}

//城市列表
export function cityList() {
  return http.get(mixService + 'movie/city', {
    page: 1,
    size: 9999
  })
}
// 禁用地区列表
export function getSpecialAreaList() {
  return http.get(adminService + 'app/area-infos/disable/list')
}

//影院信息
export function cinemaDetail(cinemaId) {
  return http.get(movieService + `cinemas/show/${cinemaId}`, {}, { 'Bm-Area-Id': -1 })
}

//座位信息
export function seatDetail(showId) {
  return http.get(movieService + `cinemas/getSeats/${showId}`)
}

/**
 * @description: 确认订单
 * @param {
 * mobile	string 用户手机号
 * seats	[
 *   {
 *     payMoney	number 每张电影票价格
 *     seat	string 座位号
 *   }
 * ]
 * showId	string 排期编号
 * }
 */
export function confirmOrder(params) {
  return http.post(movieService + 'movie/getOrderAddDetailSheng', params)
}

/**
 * @description: 创建订单
 * @param {
 * cinemaId 影院编号
 * mobile	string 用户手机号
 * seats	[
 *   {
 *     seat	string 座位号
 *   }
 * ]
 * thirdShowId	string 排期编号
 * }
 */
export function createOrder(params) {
  params.seats = params.seats.map(item => (item = item.seat))
  return http.post(mixService + 'movieOrder', params)
}

//订单列表
export function orderList(page) {
  return http.get(searchService + 'movieOrder/page', { page, size: 10 })
}

//订单详情
export function orderDetail(oid) {
  return http.get(orderService + `movieOrder/${oid}`)
}

//重新支付
export function rePay(params) {
  return http.post(mixService + 'movieOrder/rePay', params)
}

//获取城市的区
export function getAreaByCity(cid) {
  return http.get(movieService + 'area?cityId=' + cid)
}
