import http from '@/plugin/axios'
const adminService = 'boom-center-admin-service/'

//获取商品评论
export function getComment({ productId, page = 1 }) {
  return http.get(adminService + `app/userReview/product/${productId}?page=${page}`)
}

//获取门店评论
export function getCommentByStore({ storeId, page = 1, size = 10 }) {
  return http.get(adminService + `app/userReview/${storeId}?page=${page}&size=${size}`)
}

//获取轮播图
export function getBanner() {
  return http.get(adminService + 'app/decoration/banner').then(res => {
    return res.map(i => {
      i.bannerImageUrl += '?x-oss-process=style/thumb_1200'
      return i
    })
  })
}

//获取金刚区
export function getMenuZone() {
  return http.get(adminService + 'app/decoration/zone')
}

/**
 * @description: 获取平台营业执照和价格说明
 * @param {tenantId}
 * @return {*}
 */
export function getLicence() {
  return http.get(`/boom-center-admin-service/app/sysMallContent/businessLicence`)
}
export function getPriceInfo() {
  return http.get(`/boom-center-admin-service/app/sysMallContent/priceExplain`)
}

//获取退款原因
export function getRefundReason() {
  return http.get(`/boom-center-admin-service/app/RefundReason/reasonList`)
}

//获取商家助手权限
export function getPermission(id) {
  return http
    .get(
      'boom-mix-biz-service' + `/app/businessUser/check/businessUser?memberId=${id}`,
      {},
      { 'Bm-Area-Id': -1 }
    )
    .then(res => {
      let adminInfo = wx.getStorageSync('adminInfo')
      let current = 0
      if (adminInfo && adminInfo.current) {
        current = adminInfo.current
      }
      if (res.length <= current) current = 0
      if (res && res.length) {
        res.map(item => {
          if (item.storeList) {
            item.storeSuperList = item.storeList
          }
        })
      }
      wx.setStorageSync('adminInfo', { list: res, current })
    })
    .catch(err => {
      console.log(err)
      wx.setStorageSync('adminInfo', { list: [], current: 0 })
    })
}

export function getMallSetting() {
  return http.get(adminService + 'app/mallSetting')
}

export function getAllArea() {
  return http.get(adminService + 'app/area-infos')
}

export function getAllAreaV2({ hotSize = 16 }) {
  return http.get(adminService + `app/area-infos/hot-all?hotSize=${hotSize}`)
}

export function getAreaByLocation(params) {
  return http.get(adminService + `app/area-infos/current-location`, { params });
}

//开屏弹窗
export function getPopup() {
  return http.get(adminService + 'app/decoration/popUps')
}

//查询当前地区是否显示
export function checkAreaRecommendState(id) {
  return http.get(
    adminService + `app/mallDisplay/recommendProduct?areaId=${id}`
  )
}
