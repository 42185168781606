<template>
  <div class="text">
    {{ expanded ? text : shortText }}
    <template v-if="text.length > maxLength && !expanded"> ... </template>
    <div class="btn" v-if="text.length > maxLength" @click="expanded = !expanded">
      {{ expanded ? '折叠' : '展开' }}
    </div>
  </div>
</template>
<script >
export default {
  props: {
    text: {
      type: String,
      default: ''
    },
    maxLength: {
      type: Number,
      default: 62
    }
  },
  data() {
    return {
      expanded: false
    }
  },
  computed: {
    shortText() {
      return this.text.slice(0, this.maxLength)
    }
  }
}
</script>
<style lang="less" scoped>
.text {
  word-break: break-all;
}
.btn {
  color: red;
  display: inline-block;
  font-size: 13px;
}
</style>
