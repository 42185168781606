<template>
  <div>
    <div class="empty">
      <img :src="iconUrl + 'find/empty.png'" mode="widthFix" class="img" />
      <div class="text">暂时没有相关商品</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      iconUrl: process.env.VUE_APP_baseStaticUrl + 'icon/'
    }
  }
}
</script>

<style scoped lang="less">
.empty {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  .img {
    width: 50%;
  }
  .text {
    font-size: 15px;
    color: #999;
    margin-top: 15px;
  }
}
</style>
