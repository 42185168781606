import { createRouter, createWebHistory } from 'vue-router'
import routeList from 'vue-auto-routing'
import { createRouterLayout } from 'vue-router-layout'
import { isMicroApp, isDev, projectId } from '@/util/env'

const RouterLayout = createRouterLayout(layout => {
  return import('@/layout/' + layout + '.vue')
})

export const routes = [
  {
    path: '/',
    component: RouterLayout,
    children: [
      {
        path: '/',
        redirect: '/home'
      },
      {
        path: '/recharge/index',
        redirect: '/recharge'
      },
      ...routeList
    ]
  }
]

export const routeInstance = createRouter({
  history: createWebHistory(),
  routes: routes
})

routeInstance.beforeEach((to, from, next) => {
  Object.assign(history.state || {}, { current: from.fullPath })
  next()
})

routeInstance.afterEach(to => {
  if (to.meta && to.meta.title) {
    document.title = to.meta.title + ` - 贪吃商城`
  } else {
    document.title = `贪吃商城`
  }
})

export default {
  install(app) {
    if (!app) return
    app.use(routeInstance)
  }
}
