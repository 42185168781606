<template>
  <div style="margin: 15px 0">
    <div class="coupon-item" @click="handleTap">
      <div class="select-box" v-if="selectable && !disabled">
        <van-icon v-if="isSelected" name="checked" color="#e7141a" size="19px" />
        <van-icon v-else name="passed" size="19px" />
      </div>
      <div class="left">
        <img class="bg" src="https://file.mall.tanchi.shop/miniapp/icon/coupon/coupon_bg.png" alt="" />
        <div class="tag new-user" v-if="type === 'newUser'">新人特惠</div>
        <div class="tag overdue" v-else-if="type === 'overdue'">即将过期</div>
        <div class="tag normal" v-else>
          <img
            class="icon"
            :src="`${iconUrl}logo_white.png`"
            mode="aspectFill"
            v-for="item in 3"
            :style="`opacity:${(item + 1) * 0.2}`"
          />
        </div>
        <div class="value">
          <template v-if="data.couponType == 3">
            {{ data.discountRatio / 10 }}
            <div class="symbol">折</div>
          </template>
          <template v-else>
            <div class="symbol">￥</div>
            {{ data.amount }}
          </template>
        </div>
        <div class="min">
          <template v-if="data.couponType == 1"> 无门槛 </template>
          <template v-else> 满{{ data.conditionAmount }}元可用 </template>
        </div>
      </div>
      <div class="right">
        <img class="status-icon" v-if="data.used" :src="`${iconUrl}used.png`" />
        <img v-else-if="data.overdued" class="status-icon" :src="`${iconUrl}overdue.png`" />
        <div class="title">
          {{ data.couponName }}
        </div>
        <div class="subtitle">
          <div class="range">
            {{ useType[data.useType] }}
          </div>
          <div class="progress" v-if="data.progress || data.progress == 0">
            <div style="width: 50px; margin-right: 7px">
              <van-progress :percentage="data.progress" stroke-width="4px" color="#F00319" :show-pivot="false" />
            </div>
            剩余{{ 100 - data.progress }}%
          </div>
          <div class="time-down" v-if="data.timeDown">
            <div class="text">仅剩</div>
            <van-count-down use-slot :time="data.timeDown" @change="onTimeDownChange">
              <span class="item">{{ timeData.hours >= 10 ? timeData.hours : '0' + timeData.hours }}:</span>
              <span class="item">{{ timeData.minutes >= 10 ? timeData.minutes : '0' + timeData.minutes }}:</span>
              <span class="item">{{ timeData.seconds >= 10 ? timeData.seconds : '0' + timeData.seconds }}</span>
            </van-count-down>
          </div>
        </div>
        <div class="bottom">
          <div class="desc-btn" @click.stop="openDesc = !openDesc">
            使用说明
            <van-icon class="icon" name="arrow-down" :style="`transform:${openDesc ? 'rotateX(180deg)' : ''}`" />
          </div>
          <div class="time">
            {{ formatDate(data.useStartTime) }}
            ~
            {{ formatDate(data.useEndTime) }}
          </div>
        </div>
        <div class="action">
          <div class="btn" v-if="data.get && !data.geted" @click.stop="handleGet">立即领取</div>
          <template v-if="!data.couponCenter">
            <div
              class="btn"
              v-if="data.use && data.geted && (clickable || data.useType == 0) && !data.overdued && !data.used"
              @click.stop="handleUse"
              style="box-shadow: none"
            >
              去使用
            </div>
          </template>
          <template v-else>
            <div v-if="data.geted" class="geted">已领取</div>
          </template>
        </div>
      </div>
    </div>
    <div class="desc" v-if="openDesc">使用说明：{{ data.note }}</div>
  </div>
</template>

<script setup>
import { formatDate } from '@/util/filter'
</script>

<script>
export default {
  name: 'Coupon',
  props: {
    data: {
      type: Object,
      value: {}
    },
    type: {
      type: String,
      value: 'normal'
    },
    clickable: {
      type: Boolean,
      default: false
    },
    selectable: {
      type: Boolean,
      default: false
    },
    isSelected: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      iconUrl: process.env.VUE_APP_baseStaticUrl + 'icon/coupon/',
      useType: {
        0: '全场通用',
        1: '部分分类可用',
        2: '部分商品可用',
        3: '部分商家可用',
        4: '折扣全区可用'
      },
      openDesc: false
    }
  },
  methods: {
    handleTap() {
      if (!this.clickable || this.disabled) {
        return
      }
      if (this.selectable) {
        this.$emit('selected', this.data)
      } else {
        let data = this.properties.data
        app.globalData.couponData = data
        // TODO !error
        this.$router.push({
          name: 'couponDetail',
          params: this.data
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
.coupon-item {
  display: flex;
  position: relative;
  border-radius: 6px;
  overflow: hidden;
  background: #fff;
  &.disabled {
    background: red;
  }
  &:active {
    filter: brightness(95%);
  }
  .select-box {
    position: absolute;
    z-index: 9;
    top: 5px;
    right: 5px;
    line-height: 10px;
  }
  .left {
    width: 86px;
    height: 86px;
    overflow: visible;
    position: relative;
    background: transparent;
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .bg {
      position: absolute;
      z-index: 0;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      box-shadow: 2px 0px 14px 0px rgba(241, 73, 72, 0.4);
      border-radius: 8px 0px 0px 8px;
    }

    .tag {
      position: relative;
      width: fit-content;
      height: fit-content;
      z-index: 1;
      font-size: 10px;
      color: #ffffff;
      text-align: center;
      border-radius: 30px;
      padding: 2rpx 15rpx;

      &.new-user {
        background: rgba(255, 255, 255, 0.1);
      }

      &.overdue {
        background: #fff;
        color: #e7141a;
      }

      &.normal {
        .icon {
          width: 12px;
          height: 12px;
          margin: 0 2px;
          border-radius: 50%;
          overflow: hidden;
        }
      }
    }

    .value {
      position: relative;
      z-index: 1;
      color: #fff;
      text-align: center;
      font-size: 20px;
      font-weight: bold;
      white-space: nowrap;

      .symbol {
        font-size: 12px;
        display: inline-block;
        width: 7px;
      }
    }

    .min {
      position: relative;
      z-index: 1;
      text-align: center;
      font-size: 10px;
      color: #ffffff;
    }
  }
  .right {
    flex: 1;
    overflow: hidden;
    padding: 7px 15px;
    padding-left: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #fff;
    position: relative;
    height: 86px;

    .status-icon {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 55px;
    }

    &.active {
      filter: brightness(95%);
    }

    .title {
      width: 100%;
      font-size: 14px;
      font-weight: bold;
      color: #333333;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-weight: bold;
      overflow: hidden;
    }

    .subtitle {
      display: flex;
      align-items: center;
      font-size: 10px;
      color: #999;

      .range {
        color: #1886ff;
        margin-right: 6px;
      }

      .progress {
        flex: 1;
        display: flex;
        align-items: center;
        font-size: 10px;
        color: #e9191f;
      }

      .time-down {
        color: #e9191f;
        display: flex;
        align-items: center;

        .text {
          margin-right: 2px;
        }

        .item {
          position: relative;
          font-size: 10px;
          color: #e9191f;
          top: -1px;
        }
      }
    }

    .bottom {
      display: flex;

      .desc-btn {
        font-size: 10px;
        color: #999;
        margin-right: 10px;
        white-space: nowrap;

        &:active {
          color: #555;
        }

        .icon {
          font-size: 10px;
          color: #999;
          transition: all 0.5s;
        }
      }

      .time {
        font-size: 10px;
        color: #999;
        overflow: hidden;
      }
    }

    .action {
      position: absolute;
      right: 17px;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      align-items: center;
      justify-content: space-between;

      .give {
        height: fit-content;
        background: #fef3f3;
        border-radius: 30px;
        font-size: 11px;
        color: #e7141a;
        width: 73px;
        height: 29px;
        position: relative;
        z-index: 8;

        .text {
          position: relative;
          z-index: 9;
          line-height: 29px;
          text-align: center;
        }

        .bg {
          position: absolute;
          z-index: 1;
          top: 0;
          left: 0;
          width: 100%;
        }
      }

      .btn {
        width: 70px;
        height: 28px;
        line-height: 29px;
        text-align: center;
        background: #e7141a;
        border-radius: 30px;
        font-size: 11px;
        color: #ffffff;
        box-shadow: 2px 4px 8px -1px rgba(255, 0, 0, 0.2);

        &:active {
          filter: brightness(95%);
        }
      }

      .geted {
        font-size: 12px;
        color: #999;
      }
    }
  }
}
.desc {
  margin: 0;
  padding: 0;
  padding-top: 10px;
  font-size: 13px;
  word-break: break-all;
}
</style>
