<template>
  <div>
    <div class="block store">
      <div class="header" @click="store.show = !store.show" v-if="isShowTitle">
        <template v-if="store.selected !== -1">
          <div class="name">{{ store.list[store.selected].storeTitle }}</div>
          <div class="btn">
            切换门店
            <van-icon name="arrow" />
          </div>
        </template>
        <div v-else style="font-size: 15px; color: #e7141a">请选择门店</div>
        <bm-storeAddress
          :name="false"
          :data="store.list[store.selected]"
          v-if="store.selected !== -1"
        />
      </div>
      <div v-else @click="handleChangeStore">
        <div class="header" v-if="store.selected !== -1 || tempList.id">
          <div class="name">
            {{ tempList.storeTitle || store.list[store.selected]?.storeTitle }}
          </div>
          <div class="btn">
            切换门店
            <van-icon name="arrow" />
          </div>
        </div>
        <div v-else :style="style">
          <div class="text-box">
            <img class="icon" :src="`${iconUrl}search.png`" />
            <van-field class="input" readonly v-model.trim="value" placeholder="请输入用户名" />
          </div>
        </div>
      </div>
      <bm-storeAddress
        :name="false"
        :data="store.list[store.selected] || tempList"
        v-if="store.selected !== -1 || tempList.id"
      />
    </div>
    <div class="choose-store" hover-class="none" hover-stop-propagation="false">
      <van-action-sheet v-model:show="store.show">
        <div class="title" v-if="isShowTitle">
          <div></div>
          <div class="name">选择门店</div>
          <div class="btn" @click="store.show = false">
            <van-icon name="close" color="#cccccc" size="25" />
          </div>
        </div>
        <div class="search-container" v-else>
          <div class="search-box">
            <img class="icon" :src="`${iconUrl}search.png`" />
            <van-field
              v-model="inputVal"
              :border="false"
              type="text"
              clearable
              class="input"
              @input="handleInput"
              @clear="clear"
              placeholder="请填写/选择门店名称"
              placeholder-style="color:#cccccc;font-size:24rpx"
              custom-style="padding: 0;background-color: transparent;"
            />
          </div>
        </div>
        <div class="desc">
          <div class="list">
            <template v-for="(item, index) in store.list">
              <div class="item" @click="selectStore(index)">
                <img
                  class="icon"
                  :src="`${iconUrl}${
                    store.selected === index
                      ? 'order_button_selected@2x.png'
                      : 'order_button_normal@2x.png'
                  }`"
                />
                <bm-storeAddress :data="item" :clickable="false" :reserve="true" />
              </div>
            </template>
            <bm-bottomLoading :loading="store.loading" :more="store.isMore" @loadMore="loadMore" />
          </div>
        </div>
      </van-action-sheet>
    </div>
  </div>
</template>

<script>
import store from '@/plugin/store'
import { mapGetters } from 'vuex'
export default {
  props: {
    pid: {
      type: String,
      default: ''
    },
    isShowTitle: {
      // true 显示标题 false 显示输入款搜索门店
      type: String,
      default: true
    },
    style: {
      type: String
    }
  },
  data() {
    return {
      iconUrl: process.env.VUE_APP_baseStaticUrl + 'icon/common/',
      store: {
        //选择门店
        list: [],
        selected: -1,
        show: false,
        page: 1,
        loading: false,
        isMore: true,
        storeName: ''
      },
      timer: null,
      inputVal: '',
      tempList: {}
    }
  },
  computed: {
    ...mapGetters({
      location: 'common/sys/location'
    })
  },
  created() {
    this.isShowTitle && this.getStoreList()
  },
  methods: {
    clear() {
      this.inputVal = ''
      this.getStoreList()
    },
    handleChangeStore() {
      this.inputVal = this.tempList.storeTitle || ''
      this.getStoreList()
      this.store.show = !this.store.show
    },
    handleInput(e) {
      if (this.timer != null) clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.inputVal = e.target.value
        this.getStoreList()
      }, 500)
    },
    getStoreList(page = 1) {
      let location = this.location
      if (page === 1) {
        this.store.list = []
      }
      this.$api.search
        .getStoreByProduct({
          pid: this.pid,
          lat: location.lat,
          lon: location.lon,
          page,
          storeName: this.inputVal
        })
        .then(res => {
          if (res.list.length === 1) {
            this.$emit('handleChooseStore', res.list[0].id)
          }
          if (res.list && res.list.length) {
            res.list.map(item => {
              this.store.list.push(item)
            })
          }
          if (!this.isShowTitle) {
            this.store.selected = this.store.list?.findIndex(item => item.id == this.tempList.id)
          } else {
            this.store.selected = res.list.length === 1 ? 0 : -1
          }

          this.store.page = page
          this.store.isMore = page * res.size < res.total
          this.store.loading = false
        })
        .catch(() => {
          this.store.loading = false
          this.store.isMore = false
        })
    },

    loadMore() {
      if (!this.store.loading && this.store.isMore) {
        this.getStoreList(this.store.page + 1)
      }
    },

    selectStore(index) {
      this.store.selected = index
      this.store.show = false
      this.tempList = this.store.list[index]
      this.$emit('handleChooseStore', this.store.list[index].id)
    }
  }
}
</script>

<style lang="less" scoped>
.block {
  position: relative;
  padding: 15px;
  background-color: #fff;
  border-radius: 10px;
  padding-bottom: 5px;
  margin-bottom: 15px;
  overflow: hidden;
  .item {
    display: flex;
    justify-content: space-between;
    font-size: 15px;
    font-weight: 600;
    color: #343434;
    padding: 7px 0;
    background-color: #fff;
    margin-top: 15px;
    overflow: hidden;
    .price {
      color: #e7141a;
      .icon {
        width: 10px;
        height: 10px;
        margin-left: 5px;
      }
    }
  }
}
.store {
  padding: 0;
  .header {
    display: flex;
    align-items: center;
    padding: 15px;
    padding-bottom: 10px;
    background-color: #fff;
    &:active {
      filter: brightness(95%);
    }
    .name {
      flex: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 16px;
      font-weight: bold;
      color: #333333;
    }
    .btn {
      font-size: 10px;
      color: #999999;
    }
  }
  .text-box {
    // margin: 15px 0 5px;
    display: flex;
    width: 100%;
    // background: #efefef;
    border-radius: 15px;
    border: 1px solid #dfdfdf;
    align-items: center;
    overflow: hidden;
    margin-top: 13px;
    .icon {
      width: 20px;
      height: 20px;
      // margin-top: 6px;
      margin: 0 10px;
    }
    .input {
      font-size: 14px;
      height: 30px;
      line-height: 30px;
    }
    .van-cell {
      padding: 0 !important;
    }
  }
}
.choose-store {
  .title {
    width: 100%;
    font-size: 18px;
    font-weight: bold;
    color: #343434;
    // text-align: center;
    padding-left: 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 10;
    background: #fff;
    .btn {
      // position: absolute;
      // top: 15px;
      // right: 15px;
      z-index: 9;
      background-color: #fff;
      border-radius: 50%;
      // padding: 2px;
      width: 38px;
      &:active {
        filter: brightness(95%);
      }
    }
  }

  .search-container {
    padding: 15px 10px 0;
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 999;

    .search-box {
      background-color: #f7f7f7;
      border-radius: 30px;
      // border: 1px solid #e7141a;
      padding: 1px 10px;
      padding-right: 1px;
      display: flex;
      align-items: center;
      font-size: 15px;
      line-height: 30px;

      .van-cell {
        padding: 0;
        background-color: transparent;
      }

      .icon {
        margin-right: 8px;
        width: 20px;
        height: 20px;
      }

      .clear-icon {
        width: 18px;
        height: 18px;

        &:active {
          filter: brightness(95%);
        }
      }

      .input {
        flex: 1;
        line-height: 30px !important;
        padding-right: 10px;
      }
    }
  }
  .desc {
    overflow: hidden;
    .list {
      height: 100%;
      .item {
        border-bottom: 1px solid #f9f9f9;
        position: relative;
        .icon {
          width: 15px;
          height: 15px;
          position: absolute;
          right: 15px;
          top: 15px;
        }
      }
    }
  }
}
/deep/ .van-action-sheet {
  height: 70%;
}
</style>
