export function imageThumb(val, size = 200) {
  return val + `?x-oss-process=image/resize,w_${size}`
}
export function thumb1200(val) {
  return val + `?x-oss-process=style/thumb_1200`
}
export function sliceString(val, length = 15) {
  return val.toString().substring(0, length)
}
export function formatPrice(val) {
  return Number(val).toFixed(2)
}
export function formatScore(val) {
  return Number(val).toFixed(2)
}
export function formatFullDate(val) {
  if (val) {
    if (String(val).length === 10) {
      val = val * 1000
    }
    let date = new Date(val)
    return (
      date.getFullYear() +
      '-' +
      (date.getMonth() < 9 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) +
      '-' +
      (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) +
      ' ' +
      (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) +
      ':' +
      (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes())
    )
  } else {
    return '-'
  }
}
export function formatDate(val) {
  if (val) {
    if (String(val).length === 10) {
      val = Number(val) * 1000
    }
    let date = new Date(val)
    return (
      date.getFullYear() +
      '-' +
      (date.getMonth() < 9 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) +
      '-' +
      (date.getDate() < 10 ? '0' + date.getDate() : date.getDate())
    )
  } else {
    return '-'
  }
}
