import { ref, computed } from 'vue'

export const userInfoData = ref({})

export const isLogin = computed(() => {
  if (!localStorage.token || !localStorage.userInfo) {
    return false
  }
  try {
    let _userInfo = JSON.parse(localStorage['userInfo'])
    if (_userInfo) {
      userInfoData.value = _userInfo
    }
    return !!userInfoData.value?.id
  } catch (err) {
    return false
  }
})

export function getTempToken() {
  return fetch
    .get('/boom-center-user-service/app/members/getAnonymousToken?plantformId=3')
    .then(token => {
      localStorage.token = token
      return
    })
}

export function logout() {
  userInfoData.value = {}

  localStorage.removeItem('token')
  localStorage.removeItem('userInfo')
  window.location.href = '/account/login'
}

export function redirectToLoginPage() {
  userInfoData.value = {}

  localStorage.removeItem('token')
  localStorage.removeItem('userInfo')
  const pathname = encodeURIComponent(location.href.replace(location.origin, ''))
  window.location.href = `/account/login?c=${pathname}`
}
