import http from '@/plugin/axios'
const productService = 'boom-center-product-service/'
const mixService = 'boom-mix-biz-service/app/'
/**
 * @description: 获取首页轮播图
 */
export function getBanner() {
  return http.get(productService + 'banner')
}

/**
 * @description: 获取首页分类列表
 */
export function getCatelogTab() {
  return http.get(productService + 'productCategoryConfig/tab')
}

/**
 * @description: 查询商品分类配置列表
 * @param {productCategoryId}
 * @return {*}
 */
export function getCatelogList(params) {
  return http.get(productService + 'productCategory/tab/list', params)
}

/**
 * @description: 获取3级分类，
 * @param {分类id}
 */
export function getCatelog({ productCategoryId, page = 1, size = 50 }) {
  return http.get(
    productService +
      `productCategory/tab/list?productCategoryId=${productCategoryId}&page=${page}&size=${size}`
  )
}

/**
 * @description: 获取商家详情
 * @param {商家id,{lat,lon}}
 */
export function getShopDetail(id) {
  return http.get(productService + `business/detail/${id}`, { isAreaLess: true })
}

/**
 * @description: 获取商品详情
 * @param {*}
 */
export function getProductDetail(id) {
  return (
    http
      .get(productService + `product/${id}/v2`, { isAreaLess: true })
      .then(res => {
        if (res) {
          let temp = []
          res.imageUrlList.map(i => {
            if (!i.includes('?')) {
              temp.push(i + '?x-oss-process=style/thumb_1200')
            } else {
              temp.push(i)
            }
          })
          res.thumbImageList = temp
          res.purchaseInstructions = res.purchaseInstructions.replace(
            /\<img/gi,
            '<img style="width:100%;height:auto"'
          )
          res.productDetailContent = res.productDetailContent.replace(
            /\<img/gi,
            '<img style="max-width:100%;height:auto"'
          )
        }
        return res
      })
  )
}

//获取分销金
export function getDistributionAmount(params) {
  return http.get(mixService + `products/${params.id}/cal-distribution-amount-v1`, params, {
    'Bm-Area-Id': -1
  })
}

/**
 * @description: 获取预约信息
 */
export function getReserveInfo({ productId, storeId }) {
  return http.get(
    productService + `booking/store/product?productId=${productId}&storeId=${storeId}`,
    { isAreaLess: true }
  )
}

/**
 * @description: 获取所有一级商圈
 */
export function getPrimaryDistrict() {
  return http.get(productService + `businessDistrict/primary`)
}
/**
 * @description: 获取分类附近商圈商圈
 */
export function getNearbyAddrTree(params) {
  return http.get(
    productService + `businessDistrict/getProductCategoryBusinessDistrictList/${params.id}`
  )
}

export function getAllDistrict() {
  return http.get(productService + `businessDistrict/child/0`)
}

export function getAllCategory() {
  return http.get(productService + 'businessCategory/child/0')
}

//获取推荐商品
export function getRecommend({ categoryId, productId }) {
  return http.post(productService + 'product/recommendCommonCategoryProduct', {
    categoryId,
    productId
  })
}

//临时
export function getProductList({ page = 1, size = 8 }) {
  return http.get(productService + `sysAdmin/product?page=${page}&size=${size}`)
}

//首页活动
export function getActivity() {
  return http.get(productService + 'activity?businessDistrictId=0')
}

/**
 * @description: 获取活动商品
 */
export function getActivityProduct(params) {
  return http.get(
    productService + `product/productGroup/list/v2`,
    { params },
    { 'Bm-Area-Id': params.areaId }
  )
}

//获取门店商品
export function getProductByStore({ storeId, page = 0, size = 10 }) {
  return http.get(
    productService + `product/storeProduct?storeId=${storeId}&page=${page}&size=${size}`,
    { isAreaLess: true }
  )
}
//获取商家商品
export function getProductByShop({ businessId, page = 0, size = 10 }) {
  return http.get(
    productService + `product/businessProduct?businessId=${businessId}&page=${page}&size=${size}`,
    { isAreaLess: true }
  )
}

// 生成带参数的微信公众号关注二维码
export function getProductQrcode({ pid, uid }) {
  return http.get(
    productService + `wechat/generateShareWxMpQrCode?productId=${pid}&shareId=${uid}`,
    {}
  )
}

export function getProductPoster(params) {
  return http.post(productService + 'app/productPoster/v2', params, {
    isAreaLess: false
  })
}
export function getShopPoster(params) {
  return http.post(productService + 'app/productPoster/businessPoster', params, {
    isAreaLess: false
  })
}
export function getStorePoster(params) {
  return http.post(productService + 'app/productPoster/storePoster', params, {
    isAreaLess: false
  })
}
//霸王餐海报
export function getLuckyDrawPoster(params) {
  return http.post(productService + 'app/productPoster/overlordMealPoster', params, {
    isAreaLess: false
  })
}
export function getPoster_new(params) {
  return http.post(mixService + 'poster/generate', params)
}

/**
 * @description: 获取分享素材
 * @param {商品id}
 */
export function getShareAssets(id) {
  return http.get(productService + `product/shareMaterial/${id}`, { isAreaLess: true })
}
//根据商家id查询评价字段
export function getReviewType(bid) {
  return http.get(productService + 'app/reviewType/selectByBusinessId?businessId=' + bid, {
    isAreaLess: true
  })
}
//根据一级分类id查询评价字段
export function getReviewTypeByCategory(cid) {
  return http.get(
    productService + '/app/reviewType/selectByBusinessCategoryId?businessCategoryId=' + cid,
    { isAreaLess: true }
  )
}

//根据门店id获取门店电话接口，会根据相关规则进行脱敏
export function getStorePhone(id) {
  return http.get('/boom-mix-biz-service/app/store/getStorePhoneByStoreId?storeId=' + id, {
    isAreaLess: true
  })
}

// 门店营业时间
export function getStoreOpeningHoures(params) {
  return http.get(productService + `app/store/opening/hours`, params)
}

export function getStoreOpeningHouresDetail(params) {
  return http.get(productService + `app/store/opening/hours/detail`, params)
}
