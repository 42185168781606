import http from "@/plugin/axios";

const userService = "boom-center-user-service/app/";
const mixService = "boom-mix-biz-service/app/";
const msgService = "boom-center-message-service/app/";

export function getUserInfo(params) {
  return http.post(userService + "members/decryptUserInfo", params, {
    token: params.token,
  });
}
/**
 * @description: 获取临时token
 */
export function getTempToken() {
  return http.get(userService + "members/getAnonymousToken?plantformId=3");
}

/**
 * @description: 获取手机号
 * @param {encryptedData,ivStr,code}
 */
export function getPhoneNumber(params) {
  return http.post(userService + "members/wxLogin", params);
}

/**
 * @description:同步用户信息
 * @param {
 *   "avatarUrl": "string",
 *   "city": "string",
 *   "gender": 0,
 *   "nickName": "string"
 * }
 */
export function syncUserInfo(params) {
  return http.post(userService + "members/sync_basic_user_info", params);
}

export function getOpenId(code) {
  return http.post(userService + "members/codeConvertToOpenId", { code });
}

//获取我的预约列表
export function getReserveList({ page = 0, size = 10 }) {
  return http.get(userService + `books?page=${page}&size=${size}`);
}

//获取预约详情
export function getReserveDetail({ id }) {
  return http.get(userService + `books/${id}`);
}

//根据订单id获取预约记录
export function getReserveByOrder({ oid }) {
  return http.get(userService + `books/count?orderId=${oid}`);
}

//取消预约
export function cancelReserve(id) {
  return http.put(mixService + `books/${id}`);
}

//新增我的商品收藏
export function addProductCollect(params) {
  return http.post(userService + "myCollect/insertProduct", params, {
    "Bm-Area-Id": -1,
  });
}

//新增我的门店收藏
export function addStoreCollect(params) {
  return http.post(userService + "myCollect/insertStore", params, {
    "Bm-Area-Id": -1,
  });
}
//删除我的商品收藏
export function cancelProductCollect(params) {
  return http.put(
    userService + "myCollect/cancelProduct/" + params,
    {},
    { "Bm-Area-Id": -1 }
  );
}

//删除我的门店收藏
export function cancelStoreCollect(params) {
  return http.put(
    userService + "myCollect/cancelStore/" + params,
    {},
    { "Bm-Area-Id": -1 }
  );
}

//查看我的收藏列表
export function collectList(params) {
  return http.get(
    mixService + "myCollect/list",
    params,
    {},
    { "Bm-Area-Id": -1 }
  );
}

//新增商品足迹
export function addProductHistory(params) {
  return http.post(userService + "myHistory/insertProduct/v1", params, {
    "Bm-Area-Id": -1,
  });
}

//新增门店足迹
export function addStoreHistory(params) {
  return http.post(userService + "myHistory/insertStore/v1", params, {
    "Bm-Area-Id": -1,
  });
}

//查看我的足迹列表
export function historyList(params) {
  return http.get(mixService + "myHistory/list/v1", params, {
    "Bm-Area-Id": -1,
  });
}

//删除商品足迹
export function removeProductHistory(pid) {
  return http.delete(
    userService + "myHistory/removeProductId?productId=" + pid
  );
}
//删除门店足迹
export function removeStoreHistory(sid) {
  return http.delete(userService + "myHistory/removeStoreId?storeId=" + sid);
}

export function getUserStatisticsNum() {
  return http.get(
    userService + "members/getTotalData/v1",
    {},
    { "Bm-Area-Id": -1 }
  );
}

//校验手机号码
export function checkPhone() {
  return http.get(
    userService + "members/checkPhoneNumber",
    {},
    { "Bm-Area-Id": -1 }
  );
}
//获取安全码
export function getSafeCode(code) {
  return http.post(
    userService + "members/getSecurityCode",
    { code },
    { "Bm-Area-Id": -1 }
  );
}
//获取验证码
export function getPhoneCode(phone) {
  return http.get(
    msgService + `sms/send/${phone}?type=1`,
    {},
    { "Bm-Area-Id": -1 }
  );
}
//更换绑定手机号
export function changePhone(params) {
  return http.post(userService + "members/modifyPhoneNumber", params, {
    "Bm-Area-Id": -1,
  });
}

/**
 * @description: 绑定邀请人
 * @param {inviteId}
 */
export function bindInvite(params) {
  return http.put(mixService + "distribution/weak-bind", params);
}

//查询商品是否收藏
export function isCollect(pid) {
  return http.get(userService + `myCollect/product/${pid}`)
}
