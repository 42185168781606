<template>
  <div class="product-container" ref="product" :style="customStyle">
    <div class="product" @click="handleClick(data.id || data.productId)">
      <div class="warning" v-if="data.productState === 5">即将售罄</div>
      <div class="img-box" :style="`background-image:url(${cover});${imgStyle}`"></div>
      <div class="info">
        <div class="title">{{ title }}</div>
        <div class="price">
          <div class="now-price">
            <span>￥</span>
            <span class="num">
              {{
                data.salePrice || data.firstSkuSalePrice || data.productSkuList[0].salePrice || '--'
              }}
            </span>
          </div>
          <div class="old-price" v-if="data.originalPrice || data.firstSkuOriginalPrice">
            ￥{{ data.originalPrice || data.firstSkuOriginalPrice || '--' }}
          </div>
          <div class="sold" v-if="sold">
            已售<span style="color: #333; font-weight: bold">{{ sold }}</span>
          </div>
          <div style="flex: 1; text-align: right">
            <div class="btn" style="display: inline-block">立即抢购</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import productBrowswTrack from './productBrowseTrack'
export default {
  mixins: [productBrowswTrack],
  props: {
    data: {
      type: Object,
      default: {}
    },
    imgStyle: {
      type: String,
      default: ''
    },
    customStyle: {
      type: String,
      default: ''
    },
    source: {
      type: Number,
      value: 1
    }
  },
  computed: {
    title() {
      let name = this.data.productName || this.data.productSkuName
      if (name.length >= 22) {
        return name.slice(0, 22) + '...'
      }
      return name
    },
    sold() {
      let data = this.data
      try {
        return data.saleCount || data.soldNum || data.productSkuList[0].saleNum
      } catch (err) {
        return 0
      }
    },
    cover() {
      let data = this.data
      let url = data.url || data.productUrl || data.productImageUrl
      if (!url.includes('x-oss-process=style/gif')) {
        url += '?x-oss-process=style/thumb_600'
      }
      return url
    }
  },
  methods: {
    handleClick(id) {
      this.$router.push(`/product/${id}/detail?source=${this.source}`)
    }
  }
}
</script>

<style lang='less' scoped>
.product-container {
  position: relative;
  break-inside: avoid;
  overflow: hidden;
}
.product {
  background-color: #fff;
  border-radius: 15px;
  overflow: hidden;
  margin-bottom: 10px;
  padding: 10rpx;
  position: relative;
  padding: 10px;

  &:active {
    filter: brightness(95%);
  }

  .img-box {
    width: 100%;
    height: 164px;
    border-radius: 15px;
    margin-bottom: 10rpx;
    background-position: center center;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    position: relative;
    overflow: hidden;
  }

  .warning {
    position: absolute;
    z-index: 2;
    left: 0;
    top: 0;
    color: #fff;
    text-align: center;
    font-size: 14px;
    padding: 6px 12px;
    border-radius: 15px 0 15px 0;
    white-space: nowrap;
    background: linear-gradient(to right, rgba(231, 20, 26, 0.7), rgba(255, 128, 132, 0.7));
  }

  .info {
    padding: 5px;
    padding-top: 10px;

    .title {
      font-size: 15px;
      font-weight: bold;
      color: #444444;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      word-break: break-all;
      display: -webkit-box;
      text-overflow: ellipsis;
      white-space: pre-wrap;
    }

    .price {
      display: flex;
      align-items: flex-end;

      .now-price {
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #e7141a;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        .num {
          font-size: 22px;
        }
      }

      .old-price {
        overflow: hidden;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 500;
        text-decoration: line-through;
        color: #999;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 4px;
      }

      .sold {
        flex: 1;
        width: fit-content;
        padding: 2px 5px;
        font-size: 12px;
        font-weight: 500;
        color: #666;
        border-radius: 3px;
        overflow: hidden;
        position: relative;
        margin-left: 7px;
        padding-left: 7px;
        margin-bottom: 3px;

        &::after {
          content: '';
          position: absolute;
          width: 1px;
          height: 50%;
          top: 47%;
          transform: translateY(-50%);
          left: 0;
          background-color: #999;
        }
      }

      .btn {
        background: linear-gradient(241deg, #e71519 0%, #fb2d1a 100%);
        color: #fff;
        font-size: 14px;
        padding: 7px 15px;
        border-radius: 30px;
      }
    }
  }
}
</style>
