<template>
  <div>
    <div @click="handleShare"><slot></slot></div>

    <van-popup
      teleport="body"
      get-container="body"
      :overlay="false"
      v-model:show="show"
      position="bottom"
    >
      <div class="share">
        <div class="content">
          <div class="poster">
            <img :src="sharePath" class="img" />
            <div class="creating" v-if="creating">
              <img class="img" :src="`${iconUrl}share.png`" />
              <img
                class="loading"
                :src="`${iconUrl}bottom_loading.gif`"
                mode="aspectFill"
                v-if="creating"
              />
            </div>
          </div>
          <div class="action" :style="`max-height:${creating ? 0 : 200}rpx`">
            <div class="btn" v-if="!onlyPoster" @click.stop="handleCopy">
              <img class="icon" :src="`${iconUrl}share_icon_wechar@2x.png`" />
              <div>复制链接</div>
            </div>
            <div class="btn" @click.stop="savePoster">
              <img
                class="icon"
                :src="`${iconUrl + (saving ? 'bottom_loading.gif' : 'share_icon_pic@2x.png')}`"
              />
              <div>保存海报</div>
            </div>
          </div>
          <div class="close" @click="show = false">取消</div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script setup>
import useClipboard from 'vue-clipboard3'
const { toClipboard } = useClipboard()
</script>

<script>
import { mapGetters } from 'vuex'
import { Notify } from 'vant'
import userTrack from '@/util/userTrack'
import fetch from '@/plugin/axios'

export default {
  props: {
    type: {
      type: String,
      default: 'product'
    },
    store: Boolean, //分享店铺
    data: Object,
    templateId: String,
    onlyPoster: {
      type: Boolean,
      default: false
    },
    nftProductId: String // nft 的分享
  },
  data() {
    return {
      iconUrl: process.env.VUE_APP_baseStaticUrl + 'icon/common/',
      creating: true, //生成中...
      saving: false, //保存中...
      imgDraw: {}, //绘制图片的对象
      sharePath: '',
      show: false
    }
  },
  computed: {
    ...mapGetters({
      userInfo: 'common/account/userInfo',
      isShare: 'common/sys/isShare',
      isLogin: 'common/account/isLogin'
    })
  },
  watch: {
    show(v) {
      console.log(v)
      if (v) {
        this.getPoster() //后端生成
      }
    },
    isShare(v) {
      if (v) {
        this.show = true
        this.$store.commit('common/sys/update', {
          key: 'isShare',
          value: false
        })
      }
    }
  },
  methods: {
    handleShare() {
      if (this.isLogin) {
        this.show = true
      } else {
        sessionStorage['loginBackPath'] = this.$route.fullPath
        this.$router.push('/account/login?back=true')
      }
    },
    async handleCopy() {
      let url = ''
      console.log(location)
      if (this.type === 'product') {
        url = `https://${location.host}/product/${
          this.data.id || this.data.productId
        }/detail?shareId=${this.userInfo.id}&time=${new Date().getTime()}`
      } else {
        url = `${location.origin}${location.pathname}?id=${this.data.id}&shareId=${this.userInfo.id}`
      }
      //创建input
      let inputZ = document.createElement('input')
      inputZ.setAttribute('id', 'inputCopy')
      inputZ.value = url
      document.body.appendChild(inputZ)
      document.getElementById('inputCopy').select()
      //把值复制下来
      try {
        document.execCommand('Copy')
        Notify({ type: 'success', message: '复制成功' })
      } catch (err) {
        Notify({ type: 'error', message: '复制失败' })
      }
      //删除添加的input
      document.body.removeChild(inputZ);
    },
    savePoster() {
      let alink = document.createElement('a')
      alink.href = this.sharePath
      alink.target = 'blank'
      alink.download = 'share-poster' //图片名
      alink.click()
    },
    getPoster() {
      this.creating = true
      if (this.sharePath) {
        this.creating = false
        return
      }
      let userInfo = this.userInfo
      let data = this.data
      let params = {
        qrCodeType: data.posterQrCodeSet || 1,
        shareId: userInfo.id,
        userName: userInfo.nickname,
        userHeadPortrait: userInfo.avatarUrl,
        posterTempId: this.templateId
      }
      let req
      if (this.type === 'product') {
        params.productId = data.id || data.productId
        params.qrCodeEventType = 1
        req = this.$api.business.getPoster_new(params)
      } else if (this.type === 'store') {
        params.qrCodeType = data.posterQrcodeType
        params.businessId = data.businessId
        params.storeId = data.id
        req = this.$api.business.getStorePoster(params)
      } else if (this.type === 'luckyDraw') {
        params.qrCodeEventType = 4
        params.businessId = data.businessId == 0 ? null : data.businessId
        params.productId = data.productId == 0 ? null : data.productId
        params.overlordMealId = data.id
        params.qrCodeType = data.wxMiniAvailable ? 1 : 3
        // params.areaId = wx.getStorageSync('areaId') || -1
        // 锦鲤霸王餐分享
        if (data.prizeType == 3) {
          req = fetch.post('boom-mix-biz-service/app/poster/koi/generate', params, {
            // headers: {
            //   'Bm-Area-Id': '-1'
            // }
          })
        } else {
          req = fetch.post('boom-mix-biz-service/app/poster/generate', params, {
            // headers: {
            //   'Bm-Area-Id': '-1'
            // }
          })
        }
      } else if (this.type === 'nft') {
      } else {
        params.qrCodeType = data.posterQrcodeType
        params.businessId = data.id
        req = this.$api.business.getShopPoster(params)
      }
      req
        .then(res => {
          this.sharePath = res + '?x-oss-process=style/thumb_600'
          this.creating = false
          console.log(this.creating)
        })
        .catch(err => {
          this.show = false
          Notify({
            type: 'warning',
            message: err.msg || '获取海报失败'
          })
        })
      this.shareTrack()
    },
    shareTrack() {
      let path = this.$route.path
      let type = 'product_share'
      if (path.includes('product/')) {
        type = 'product_share'
      } else if (path.includes('shop/detail')) {
        type = 'business_share'
      } else if (path.includes('shop/store')) {
        type = 'store_share'
      } else {
        type = 'mini_share'
      }
      userTrack.submitTrack({
        event_type: 'user_share',
        event_page: type,
        event_from: 1,
        event_value: {
          value: this.$route.params.id || this.$route.query.id || this.$route.path
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.share {
  height: 100vh;
  overflow: auto;
  background: rgba(0, 0, 0, 0.7);
  .content {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    box-sizing: border-box;
    background: transparent;
    overflow: hidden;
    // padding-bottom: 20vh;
    padding-bottom: 84px;
    .poster {
      width: 70%;
      min-width: 250px;
      padding: 15px 0;
      margin: 0 auto;
      border-radius: 20rpx;
      position: relative;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      .img {
        width: 100%;
        height: auto;
        border-radius: 7px;
      }
      .widget {
        flex: 1;
        position: relative;
        z-index: 1;
      }
      .creating {
        width: 100%;
        min-width: 250px;
        position: relative;
        top: 0;
        left: 0;
        z-index: 10;
        overflow: hidden;
        transition: all 0.5s;
        .img {
          width: 100%;
          top: 0;
          left: 0;
          border-radius: 20rpx;
        }
        .loading {
          position: absolute;
          width: 40px;
          height: 40px;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
    .action {
      background-color: #fff;
      border-radius: 20rpx;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      // padding: 30rpx;
      // padding-bottom: 20rpx;
      transition: all 0.5s;
      overflow: hidden;
      padding: 10px;
      margin: 15px;
      margin-top: 0;
      border-radius: 10px;
      .btn {
        position: relative;
        text-align: center;
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #333333;
        margin: 20rpx 0;
        .icon {
          width: 45px;
        }
        &:active {
          filter: brightness(95%);
        }
      }
      .share-btn {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
      }
    }
    .close {
      background-color: #fff;
      border-radius: 10px;
      padding: 12px;
      text-align: center;
      margin: 15px;
      margin-top: 0;
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 500;
      &:active {
        filter: brightness(95%);
      }
    }
  }
}
.van-popup {
  background: transparent;
  z-index: 99999 !important;
}
</style>
