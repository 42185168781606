<template>
  <div class="loadingContainer">
    <van-loading type="spinner" color="#fff" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  props: {
    foo: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    bar: 'bar'
  }),
  computed: {
    ...mapGetters({})
  },
  watch: {},
  methods: {
    ...mapActions({})
  },
  created() {},
  mounted() {}
}
</script>

<style lang="less" scoped>
.loadingContainer {
  position: fixed;
  z-index: 99999999999 !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: @loadingIndex;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
</style>
