import userTrack from '@/util/userTrack'
export default {
  data() {
    return {
      isTrack: false,
      timer: null,
      inTime: 0,
    }
  },
  mounted() {
    let sourceMap = {
      1: 1,
      11: 1,
      12: 1,
      3: 2,
      7: 3,
      15: 4
    }
    if (!sourceMap[this.source]) {
      return
    }
    new IntersectionObserver((entries, observer) => {
      if (entries[0].isIntersecting) {
        let inTime = new Date().getTime()
        let data = this.data
        console.log(`${data.productName}：出现`)
        let timer = setTimeout(() => {
          if (this.isTrack) {
            console.log(`${data.productName}：已打点，不重复计算`)
          } else {
            this.isTrack = true
            console.log(`${data.productName}：5s打点`)
            userTrack.submitTrack({
              event_type: 'page_view',
              event_page: 'product_browse',
              event_from: sourceMap[this.source],
              event_value: {
                product_id: data.productId || data.id
              }
            })
          }
          this.timer = timer
          this.inTime = inTime
        }, 5000)
      } else {
        if (this.inTime) {
          let duration = (new Date().getTime() - this.inTime) / 1000
          console.log(`${this.data.productName}：消失,浏览${duration}s`)
          clearTimeout(this.timer)
          this.inTime = 0
        }
      }
    }, { rootMargin: "-180px 0px -150px 0px" }).observe(this.$refs.product)
  },
  destroyed() {
    clearTimeout(this.timer)
  }
}
