<template>
  <div :class="`to-top ${show ? 'slideIn' : 'slideOut'}`" @click="toTop">
    <van-icon name="arrow-up" size="20px" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false
    }
  },
  mounted() {
    window.addEventListener('scroll', this.onScroll)
  },
  destroyed() {
    window.removeEventListener('scroll', this.onScroll)
  },
  methods: {
    toTop() {
      window.scrollTo(0, 0)
    },
    onScroll() {
      let scrollTop =
        document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop
      if (scrollTop > 600) {
        this.show = true
      } else {
        this.show = false
      }
    }
  }
}
</script>

<style lang='less' scoped>
.to-top {
  position: fixed;
  z-index: 99;
  bottom: 70px;
  right: 20px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 1px 2px 5px 0 rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: width 0.3s, height 0.3s;
  overflow: hidden;
  opacity: 0;
  &:active {
    filter: brightness(95%);
  }
}
.slideIn {
  animation: slideIn 0.3s forwards;
}
.slideOut {
  animation: slideOut 0.3s forwards;
}
@keyframes slideIn {
  0% {
    transform: translateY(50%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slideOut {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(50%);
    opacity: 0;
  }
}
</style>
