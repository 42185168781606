<template>
  <div>
    <div class="store-address" @click="handleClick">
      <div class="name" v-if="name">
        <div class="text" :style="titleStyle">
          {{ data.storeTitle || '--' }}
        </div>
        <text class="tip" v-if="near">最近</text>
        <div class="tip" v-if="reserve">预约</div>
        <div class="tip no-reserve" v-if="noReserve">无需预约</div>
      </div>
      <div class="info">
        <div class="left">
          <div class="item">
            <img class="icon" :src="iconUrl + 'icon_time@2x.png'" />
            <div class="value">
              {{ data.openingHours || '--' }}
            </div>
          </div>
          <div class="item">
            <img class="icon" :src="iconUrl + 'icon_dizhi@2x.png'" />
            <div class="value">
              {{ data.storeAddress || '--' }}
            </div>
          </div>
        </div>
        <div class="action">
          <div class="btn">
            <a
              @click.stop="empty"
              :href="`https://uri.amap.com/marker?position=${data?.lon || data.location?.lon},${
                data?.lat || data.location?.lat
              }&name=${data.storeAddress}`"
              target="_blank"
            ></a>
            <img class="icon" :src="iconUrl + 'icon_daohang@2x.png'" />
            <div class="text">{{ distance }}</div>
          </div>
          <template v-if="!data.noShowPhone">
            <div class="divider" />
            <div class="btn" @click.stop="call">
              <img class="icon" :src="iconUrl + 'icon_bohao@2x.png'" />
              <div class="text">拨号</div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <van-action-sheet v-model:show="callPhone" title="点击拨打">
      <div class="choose-phone" @click.stop="empty">
        <div class="item" v-for="item in phone">
          <a @click.stop="empty" :href="`tel:${item}`"></a>
          {{ item }}
          <van-icon name="phone" size="20" />
        </div>
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
import { Notify } from 'vant'
import userTrack from '@/util/userTrack'
import { mapGetters } from 'vuex'
export default {
  props: {
    action: {
      //是否显示右边按钮
      type: Boolean,
      default: true
    },
    reserve: {
      type: Boolean,
      default: false
    }, //是否显示预约
    noReserve: {
      type: Boolean,
      default: false
    },
    near: {
      type: Boolean,
      default: false
    },
    name: {
      //是否显示门店名称
      type: Boolean,
      default: true
    },
    data: {
      type: Object,
      default: {}
    },
    clickable: {
      type: Boolean,
      default: true
    },
    titleStyle: String,
    source: {
      //来源
      type: Number,
      default: 1 //1-商品，2-门店，3-商家
    }
  },
  data() {
    return {
      iconUrl: process.env.VUE_APP_baseStaticUrl + 'icon/common/',
      phone: [],
      callPhone: false
    }
  },
  watch: {
    data(v) {
      try {
        this.phone = this.data.storeTelephone.split(',')
      } catch {
        this.phone = []
      }
    }
  },
  computed: {
    ...mapGetters({
      isLogin: 'common/account/isLogin'
    }),
    distance() {
      // return Math.round(this.data.distanceMetres * 100) / 100;
      if (this.data.distanceMetres < 1000) {
        return Math.round(this.data.distanceMetres) + 'm' || '--m'
      } else {
        return (this.data.distanceMetres / 1000).toFixed(2) + 'km' || '--km'
      }
    }
  },
  mounted() {},
  methods: {
    empty() {
      console.log('ssssssssssssss')
    },
    call: function () {
      if (!this.isLogin) {
        this.$router.replace('/account/login?back=true')
        return
      }

      this.$api.business
        .getStorePhone(this.data.id)
        .then(res => {
          this.phone = res.storeTelephone.split(',')
          this.callPhone = true
          if (res.flag) {
            Notify({ type: 'warning', message: '操作频繁' })
          }
          userTrack.submitTrack({
            event_type: 'user_click',
            event_page: 'store_phone_click',
            event_from: this.source,
            event_value: {
              store_id: this.data.id,
              flag: res.flag
            }
          })
        })
        .catch(err => {
          Notify({ type: 'warning', message: err.msg || '获取门店电话错误' })
        })
    },
    openMap: function () {
      let data = this.data
      wx.openLocation({
        name: data.storeTitle,
        latitude: data.lat || data.location.lat,
        longitude: data.lon || data.location.lon,
        address: this.data.storeAddress
      })
    },
    handleClick() {
      if (this.clickable && this.data.id) {
        this.$router.push({ path: '/shop/store', query: { id: this.data.id } })
      }
    }
  }
}
</script>

<style lang="less" scoped>
.store-address {
  position: relative;
  &:active {
    background-color: rgba(0, 0, 0, 0.05);
  }
  user-select: none;
  .name {
    width: 100%;
    font-size: 15px;
    font-weight: bold;
    color: #343434;
    display: flex;
    align-items: center;
    position: relative;
    padding: 15px;
    padding-bottom: 10px;
    .text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .tip {
      width: fit-content;
      background: #fef3f3;
      border-radius: 30px;
      font-size: 12px;
      font-weight: 500;
      color: #e7141a;
      padding: 1px 8px;
      margin-left: 5px;
      white-space: nowrap;
    }
    .no-reserve {
      background: rgba(255, 255, 255, 0.5);
      color: #333333;
    }
  }
  .info {
    display: flex;
    align-items: center;
    padding: 15px;
    padding-right: 0;
    padding-top: 0;
    .left {
      flex: 1;
      font-size: 12px;
      font-weight: 500;
      color: #9a9a9a;
      overflow: hidden;
      .item {
        display: flex;
        align-items: center;
        margin-top: 10px;
        .icon {
          width: 13px;
          height: 13px;
          margin-right: 5px;
          // margin-top: 5rpx;
        }
        .value {
          flex: 1;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
    .action {
      width: 120px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      transform: translateY(5px);
      overflow: hidden;
      .divider {
        width: 1px;
        height: 60rpx;
        background: #e9e9e9;
      }
      .btn {
        flex: 1;
        text-align: center;
        padding: 5px 0;
        height: 55px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        // overflow: hidden;
        &:active {
          background-color: rgba(0, 0, 0, 0.05);
        }
        .icon {
          width: 20px;
          height: 20px;
        }
        .text {
          font-size: 10px;
          margin-top: 10px;
          color: #9a9a9a;
          opacity: 0.9;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        a {
          position: absolute;
          width: 50%;
          height: 100%;
          // background: red;
        }
      }
    }
  }
}
.choose-phone {
  max-height: 50vh;
  overflow: auto;
  padding-bottom: 100px;
  .item {
    font-size: 18px;
    padding: 15px;
    background: #fff;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    a {
      position: absolute;
      z-index: 9;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }
    &:active {
      filter: brightness(95%);
    }
  }
}
</style>
