<template>
  <div>
    <div class="product-h" ref="product" @click="handleClick">
      <img
        class="img"
        :src="
          data.productImageUrl ||
          data.productUrl ||
          data.productSkuImageUrl + '?x-oss-process=style/thumb_600'
        "
        mode="aspectFill"
      />
      <div class="info">
        <div class="title">
          <div
            v-html="data.productTitleProductName || data.title || data.firstSkuName || data.productName || data.productSkuName"
            class="rich-text"
          ></div>
        </div>
        <div class="desc" v-if="data.productDescription">
          {{ data.productDescription }}
        </div>
        <div class="sold-dis">
          <div class="sold" v-if="saleCount">已售 {{ saleCount }}</div>
          <div class="distance">
            <div v-if="data.businessDistrictTitle">{{ data.businessDistrictTitle }}</div>
            <div v-if="data.distanceMetres">{{ distance }}</div>
          </div>
        </div>
        <div class="price">
          <div class="now-price">
            ￥
            <span class="num">
              {{ data.salePrice || data.firstSkuSalePrice || data.productSkuList[0].salePrice }}
            </span>
          </div>
          <div class="old-price" v-if="data.originalPrice || data.firstSkuOriginalPrice">
            ￥{{ data.originalPrice || data.firstSkuOriginalPrice }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import productBrowswTrack from './productBrowseTrack'
export default {
  mixins: [productBrowswTrack],
  props: {
    data: {
      type: Object,
      default: {}
    },
    source: {
      type: Number,
      value: 1
    }
  },
  computed: {
    saleCount() {
      if (this.data.productSkuList && this.data.productSkuList[0]) {
        return this.data.productSkuList[0].saleNum
      }
      return this.data.soldNum || this.data.saleCount || 0
    },
    distance() {
      if (this.data.distanceMetres < 1000) {
        return Math.round(this.data.distanceMetres) + 'm'
      } else {
        return (this.data.distanceMetres / 1000).toFixed(2) + 'km'
      }
    }
  },
  methods: {
    handleClick() {
      if (this.data.productId) {
        this.$router.push(`/product/${this.data.productId}/detail?source=${this.source}`)
      }
    }
  }
}
</script>

<style scoped lang="less">
.product-h {
  display: flex;
  padding: 10px;
  border-radius: 10px;
  background-color: #fff;
  margin-bottom: 12px;
  &:active {
    filter: brightness(95%);
  }
  .img {
    height: 110px;
    width: 110px;
    overflow: hidden;
    border-radius: 10px;
    object-fit: cover;
  }
  .info {
    flex: 1;
    margin-left: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 3px;
    overflow: hidden;
    .title {
      font-size: 15px;
      font-weight: bold;
      color: #444444;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      word-break: break-all;
      display: -webkit-box;
      text-overflow: ellipsis;
      white-space: pre-wrap;
    }
    .desc {
      font-size: 10px;
      color: #999;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .sold-dis {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      .sold {
        width: fit-content;
        padding: 3px 5px;
        background: #f7f7f7;
        font-size: 12px;
        font-weight: 500;
        color: #999999;
        border-radius: 3px;
        overflow: hidden;
      }
      .distance {
        font-size: 12px;
        font-weight: 500;
        display: flex;
        align-items: center;
        color: #999999;
        line-height: 12px;
        width: fit-content;
        div {
          &:last-child {
            padding-left: 10px;
          }
        }
      }
    }

    .price {
      display: flex;
      align-items: flex-end;
      .now-price {
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #e7141a;
        margin-right: 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        .num {
          font-size: 18px;
        }
      }
      .old-price {
        flex: 1;
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: 500;
        text-decoration: line-through;
        color: #cccccc;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 3px;
      }
    }
  }
}
</style>
