export default {
  install(app) {
    if (!app) return
    const moduleList = require.context('@/component/common', false, /\.vue$/)
    moduleList.keys().forEach(fileName => {
      const moduleName = 'bm-' + fileName.substring(2, fileName.length - 4)
      app.component(moduleName, moduleList(fileName).default)
    })
  }
}
