import fetch from '@/plugin/axios'

const state = {
  foo: 'bar'
}
const getters = {
  foo: state => state.foo
}
const mutations = {
  update: (state, { key, value }) => (state[key] = value)
}
const actions = {
  foo({ commit }, payload) {
    commit('update', {
      key: 'foo',
      value: payload
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
