import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

const vuexLocal = new VuexPersistence({
  modules: ['common/account', 'common/sys']
})

let modules = {}
const moduleList = require.context('@/store', true, /\.js$/)
moduleList.keys().forEach(fileName => {
  const moduleName = fileName.substring(2, fileName.length - 3)
  modules[moduleName] = moduleList(fileName).default
})

export default new Vuex.Store({
  modules,
  plugins: [vuexLocal.plugin]
})
