import { createApp, provide } from 'vue'
import App from './App.vue'

import routerPlugin from '@/plugin/router'
import storePlugin from '@/plugin/store'
import vantPlugin from '@/plugin/vant'
import axiosInstance from '@/plugin/axios'
import api from '@/plugin/api'
import globalComponent from '@/plugin/component'
import { log } from '@/util/log'
import { Dialog } from 'vant'
import qiankunPlugin from '@/plugin/qiankun'

const app = createApp(App)

app.use(storePlugin)
app.use(routerPlugin)
app.use(vantPlugin)
app.use(globalComponent)
app.use(qiankunPlugin)

app.config.globalProperties.$http = axiosInstance
app.config.globalProperties.$log = log
app.config.globalProperties.$api = api

app.mount('#app')
