<template>
  <div ref="loading">
    <div class="loading" v-if="loading">
      <img class="img" :src="iconUrl + 'bottom_loading.gif'" mode="widthFix" />
      加载中...
    </div>
    <div v-if="!more && !loading" class="no-more">没有更多了</div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      iconUrl: process.env.VUE_APP_baseStaticUrl + 'icon/common/'
    }
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    more: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    new IntersectionObserver((entries, observer) => {
      if (entries[0].isIntersecting) {
        this.$emit('loadMore')
      }
    }).observe(this.$refs.loading)
  }
}
</script>
<style scoped lang="less">
.loading {
  position: relative;
  text-align: center;
  color: #999999;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 15px;
  font-size: 15px;
  .img {
    width: 20px;
    margin-right: 5px;
  }
}
.no-more {
  text-align: center;
  font-size: 15px;
  padding: 15px 0;
  color: #999999;
}
</style>
