import { registerMicroApps, start } from 'qiankun'
import { routeInstance } from '@/plugin/router'
import fetch from '@/plugin/axios'
import {
  isLogin,
  userInfoData,
  getTempToken,
  logout,
  redirectToLoginPage
} from '@/provider/common/account'

import { isDev } from '@/util/env'

const allSubAppPortMap = {
  nft: 25501,
  mall: 25502,
  // order: 25503,
  user: 25504,
  activity: 25505,
  merchant: 25506
}

let commonProp = {
  config: {},
  data: {
    isLogin: isLogin,
    userInfo: userInfoData
  },
  method: {
    getTempToken: getTempToken,
    logout: logout,
    redirectToLoginPage: redirectToLoginPage
  },
  env: {
    isDev,
    bmEnv: process.env.VUE_APP_env,
    apiBaseUrl: process.env.VUE_APP_apiBaseUrl,
    tenantId: process.env.VUE_APP_tenantId,
    publicPath: process.env.VUE_APP_publicPath
  },
  util: {
    isLogin: () => {
      try {
        let _userInfo = JSON.parse(localStorage['userInfo'])
        return !!_userInfo?.id
      } catch (err) {
        return false
      }
    },
    mainRouter: routeInstance,
    axios: fetch,
    log: console.log
  }
}
const microAppList = Object.keys(allSubAppPortMap).map(i => {
  return {
    name: i,
    entry: getEntry(i),
    activeRule: `/${i}`,
    props: { ...commonProp },
    container: '#subAppContainer'
  }
})

function getEntry(name) {
  if (isDev) {
    const port = allSubAppPortMap[name]
    return getLocalUrl(port)
  } else {
    return getOnlineUrl(name)
  }
}
function getLocalUrl(port) {
  const host = location.hostname
  return `//${host}:${port}`
}
function getOnlineUrl(name) {
  return `/child/${name}/`
}

export default {
  install(app) {
    if (!app) return
    registerMicroApps(microAppList)
    start()
  }
}
