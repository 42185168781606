import fetch from '@/plugin/axios'

const state = {}
const getters = {}
const mutations = {
  update: (state, { key, value }) => (state[key] = value)
}
const actions = {
  getFitUpConfig(store, configId) {
    const areaId = store.rootGetters['common/sys/currentAreaId']
    return fetch
      .get('/boom-center-config-service/app/clientAndAreaConfiguration/findConfigByAdminParam', {
        params: {
          configSet: configId,
          areaId: areaId,
          client: `mall-h5`
        }
      })
      .then(res => {
        if (res.content) {
          return res.content
        }
        return Promise.reject()
      })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
