import fetch from '@/plugin/axios'

const state = {
  productDetail: {
    gift: [
      {
        giftCheckCode: [],
        giftSurplusNum: 3,
        orderRelationId: '1458735916510429185',
        showRefund: true,
        giftSkuTotalNum: 3,
        productName: '礼包1'
      }
    ],
    notes: '',
    orderShowStatus: 1,
    mobile: '13633711614',
    totalMoney: 0.03,
    showRefund: true,
    checkCode: [],
    orderTime: 1636624719000,
    payMoney: 0.03,
    payType: 1,
    tenantId: 1,
    isGift: 1,
    totalDiscountMoney: 0,
    id: '1458735915675656194',
    overdueTime: 1636626519000,
    payStatus: 3,
    skuInfo: {
      productId: '',
      businessId: '',
      businessLogo: '',
      businessName: '',
      discountMoney: 0,
      productName: '',
      skuPayMoney: 0,
      skuName: '',
      limitedTimeSaleId: '',
      skuTotalMoney: 0,
      skuTotalNum: 2,
      tenantId: 1,
      skuPic: '',
      id: '',
      skuId: ''
    },
    memberId: ''
  }
}
const getters = {
  productDetail: state => state.productDetail,
  productDistributionInfo: state => state.productDistributionInfo,
  recommend: state => state.recommend
}
const mutations = {
  update: (state, { key, value }) => (state[key] = value)
}
const actions = {
  async getProductDetail(store, productId) {
    let res = await fetch.get(`/boom-mix-biz-service/app/order/v1/${productId}`, {
      headers: { 'Bm-Area-Id': -1 }
    })
    store.commit('update', {
      key: 'productDetail',
      value: res
    })
    return res
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
