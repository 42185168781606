import http from "@/plugin/axios";

const orderService = "boom-center-order-service/businessAdmin/app/";
const userService = "boom-center-user-service/businessAdmin/";
const mixService = "boom-mix-biz-service/businessAdmin/app/";
const stService = "boom-center-statistics-service/";
const productService = "/boom-center-product-service/";
const adminService = "/boom-center-admin-service/businessAdmin/";
const searchService = "boom-center-search-service/";

function businessId() {
  let adminInfo = wx.getStorageSync("adminInfo");
  if (adminInfo.list[adminInfo.current]) {
    return adminInfo.list[adminInfo.current].businessId;
  }
  return 0;
}
//核销码列表
export function checkList({ page = 1, size = 10, storeIds }) {
  let ids = "";
  storeIds.map((item) => (ids += "&storeIdList=" + item));
  return http.get(
    mixService +
      `orderCheckCode/check?businessId=${businessId()}&page=${page}&size=${size}${ids}`
  );
}

//自助核销列表
export function getSelfCheck({ page = 1, size = 10, storeIds }) {
  let ids = "";
  storeIds.map((item) => (ids += "&storeIdList=" + item));
  return http.get(
    mixService +
      `orderCheckCode/check/self?businessId=${businessId()}&page=${page}&size=${size}${ids}`
  );
}

//流水-月份列表
export function getMonthStatistics({ storeIdList, page, size }) {
  storeIdList = storeIdList.join(",");
  return http.get(
    "boom-mix-biz-service/" +
      `businessAdmin/stores/sell-amount/flows/statistics`,
    {
      businessId: businessId(),
      storeIdList,
      page,
      size,
    }
  );
}
//流水-月份
export function getMonthDetail({ date, page, size, storeIdList }) {
  storeIdList = storeIdList.join(",");
  return http.get(
    "boom-mix-biz-service/" + `businessAdmin/stores/sell-amount/flows`,
    {
      businessId: businessId(),
      date,
      storeIdList,
      page,
      size,
    }
  );
}

export function getMonthWithdrawStat({ storeIdList, page, size }) {
  storeIdList = storeIdList.join(",");
  return http.get(
    "boom-mix-biz-service/" +
      `businessAdmin/app/businessAccount/withdraw-statistics`,
    {
      businessId: businessId(),
      storeIdList,
      page,
      size,
    }
  );
}
export function getMonthWithdrawList({ date, page, size, storeIdList }) {
  storeIdList = storeIdList.join(",");
  return http.get(
    "boom-mix-biz-service/" + `businessAdmin/app/businessAccount/withdraws`,
    {
      businessId: businessId(),
      date,
      storeIdList,
      page,
      size,
    }
  );
}

//流水-核销详情
export function getCheckDetail({ memberId, orderCheckCodeId }) {
  return http.get(
    "boom-mix-biz-service/businessAdmin/" +
      `stores/sell-amount/flows/check?businessId=${businessId()}&memberId=${memberId}&orderCheckCodeId=${orderCheckCodeId}`
  );
}

//流水-提现详情
export function getTakeDetail({ id }) {
  return http.get(
    "boom-mix-biz-service/businessAdmin/" +
      `stores/sell-amount/flows/withdraw?businessId=${businessId()}&id=${id}`
  );
}

export function getTakeDetailByNormal({ id }) {
  return http.get(
    "boom-mix-biz-service/businessAdmin/" +
      `app/businessAccount/flow/withdraw/direct?businessId=${businessId()}&id=${id}`
  );
}

export function getReserveStatistics({ storeId = [], page = 1, size = 100 }) {
  let params = {
    businessId: businessId(),
    page,
    size,
  };
  if (storeId.length === 1) params.storeId = storeId[0];
  return http.get(userService + `books/statistics?`, params);
}

//预约列表
export function getReserveList({ bookDay, storeId = "" }) {
  return http.get(
    userService +
      `books/store-sku-statistics?bookDay=${bookDay}&businessId=${businessId()}&storeId=${storeId}`
  );
}

/**
 * @description: 获取预约详情
 * @param { 
    bookDay,
    bookTimeId,
    skuId,
    storeId
  }
 */
export function getReserveDetail(params) {
  params.businessId = businessId();
  return http.get(userService + "books", params);
}

/**
 * @description: 提现
 * @param {
 *    "bankMemo": "string",
*     "businessId": 0,
*     "remark": "string",
*     "storeList": [
  *     {
  *       "storeId": 0,
    *     "storeName": "string"
    *   }
      ]
 * } 
 */
export function takeMoney(params) {
  params.businessId = businessId();
  return http.post(
    mixService + "businessAccount/withdraw?businessId=" + businessId(),
    params
  );
}

//获取门店核销统计数据
export function getCheckData({
  queryType = 1,
  startTime = 1,
  endTime = 1,
  storeIds = [],
}) {
  storeIds = storeIds.join(",");
  return http.get(stService + `businessAdmin/stores/check-off`, {
    businessId: businessId(),
    queryType,
    startTime,
    endTime,
    storeIds,
  });
}

//查询门店订单列表
export function getOrderListByStore({
  page = 1,
  queryType = 1,
  startTime = 1,
  endTime = 1,
  storeIds = [],
}) {
  storeIds = storeIds.join(",");
  return http.get(
    "boom-mix-biz-service/" + `businessAdmin/stores/check-off/list`,
    {
      businessId: businessId(),
      queryType,
      startTime,
      endTime,
      page,
      storeIds,
    }
  );
}

//查询商品已核销订单列表
export function getOrderListByProduct(params) {
  params.businessId = businessId();
  params.storeIdList = params.storeIdList.join(",");
  return http.get(
    "boom-mix-biz-service/" + "businessAdmin/products/checks/order-list",
    params
  );
}

//首页提现数据
export function getWithdrawMoney(sid = []) {
  let ids = "";
  if (sid[0] === "allStorePrivilege") {
    ids = "&allStorePrivilege=1";
  } else {
    sid.map((item) => (ids += "&storeIds=" + item));
  }
  return http.get(
    stService +
      `businessAdmin/stores/sell-amount?businessId=${businessId()}&queryType=0${ids}`
  );
}

//首页商品统计数据
export function getProductStat(params) {
  params.storeIds = params.storeIds.join(",");
  params.businessId = businessId();
  return http.get(
    stService + `businessAdmin/products/checks/statistics`,
    params
  );
}

//首页商品数据
export function getProductData(params) {
  // let ids = "";
  // sid.map((item) => (ids += "&storeIds=" + item));
  params.storeIds = params.storeIds.join(",");
  params.businessId = businessId();
  return http.get(stService + `businessAdmin/products/checks`, params);
}

/**
 * @description: 根据一个核销码获取该订单下的其他核销码---------------------聚合
 * @param {businessUserId,checkCode}
 */
export function getCodebyCode(params) {
  return http.get(
    "boom-mix-biz-service/" + `businessAdmin/app/orderCheckCode/sureDetail`,
    params
  );
}

/**
 * @description: 商家核销-
 * @param { checkCode,checkCount,checkStoreId,checkStoreName}
 */
export function checkCode(params) {
  return http.post(
    mixService + `orderCheckCode/businessCheck?businessId=${params.businessId}`,
    params,
    { "Bm-Area-Id": -1 }
  );
}

//根据二级商户号id查询银行卡信息
export function getBankInfo(id) {
  return http.get(
    productService + `subMerchant/accountInfo/${id}?businessId=${businessId()}`
  );
}

//可提现商户号及门店列表
export function getTakeStoreAndMch(storeIds = []) {
  let ids = "";
  storeIds.map((item) => (ids += "&storeIdList=" + item));
  return http.get(
    `boom-mix-biz-service/businessAdmin/app/businessAccount/money/canWithdraw/mch/store?businessId=${businessId()}${ids}`
  );
}
//获取提现总金额
export function getTakeAmount({ storeIdList = [""] }) {
  let ids = "";
  storeIdList.map((item) => (ids += "&storeIdList=" + item));
  return http.get(
    mixService +
      `businessAccount/money/canWithdraw?businessId=${businessId()}&storeIdList=${storeIdList}`
  );
}
//查询门店人员
export function getPersonByStore_v2({ sid = [], page = 1, size = 10 }) {
  let allStorePrivilege = 0;
  if (sid[0] === "allStorePrivilege") {
    allStorePrivilege = 1;
    sid = [];
  }
  sid = sid.join(",");

  return http.get(
    "boom-mix-biz-service/businessAdmin/" + `businessUser/store/personnel`,
    {
      businessId: businessId(),
      storeIds: sid,
      allStorePrivilege,
      page,
      size,
    }
  );
}

//我的今日核销
export function getCheckToday(id) {
  return http.get(
    stService +
      `businessAdmin/app/orderCheckCode/checkCode?businessId=${businessId()}&id=${id}`
  );
}

export function getSelfCheckToday(sid) {
  let ids = "";
  sid.map((item) => (ids += "&storeIds=" + item));
  return http.get(
    stService +
      `businessAdmin/app/orderCheckCode/selfCheckCode?businessId=${businessId()}${ids}`
  );
}

//根据商品id查询门店
export function getStoreByProduct({
  pid,
  lat = 0,
  lon = 0,
  page = 1,
  size = 10,
}) {
  return http.get(
    searchService +
      `store/search/product?businessId=${businessId()}&productId=${pid}&lat=${lat}&lon=${lon}&page=${page}&size=${size}`
  );
}

//核销码搜索订单
export function searchCheckedCode(code) {
  return http.get(
    mixService +
      `orderCheckCode/search/checkCode?businessId=${businessId()}&checkCode=${code}`
  );
}

//查询绑定人员的二维码信息
export function encodeBindQrcode(id) {
  return http.get(
    "boom-center-admin-service/app/" + `businessUserScanCode/${id}`
  );
}

//扫码绑定商家人员
export function bindBusinessMember(params) {
  return http.post(
    "boom-mix-biz-service/app/" +
      "businessUser/save/businessUser?businessId=" +
      params.businessId,
    params
  );
}

//自助核销码列表
export function getSelfCheckCode(params) {
  params.businessId = businessId();
  return http.get(mixService + "orderCheckCode/store-self-codes", params);
}

//获取默认角色
export function getDefaultRole() {
  return http.get(
    "boom-mix-biz-service/businessAdmin/businessUser" +
      "/business/default/roleList",
    { businessId: businessId() }
  );
}

//获取商家角色
export function getBusinessRole() {
  return http.get(
    "boom-mix-biz-service/businessAdmin/businessUser" + "/select/businessRole",
    { businessId: businessId() }
  );
}

//添加商家人员扫码信息
export function addQrcodeInfo(params) {
  params.businessId = businessId();
  return http.post(
    "boom-mix-biz-service/businessAdmin/businessUser?businessId=" +
      businessId(),
    params
  );
}

//删除商家人员
export function deleteMember(id) {
  return http.delete(
    "boom-mix-biz-service/businessAdmin/businessUser" +
      `/${id}?businessId=${businessId()}`
  );
}

//获取可核销门店
export function getStoreListByCheck({ cid, bid }) {
  return http.get(
    `boom-mix-biz-service/businessAdmin/app/orderCheckCode/checkable-stores/${bid}`,
    { businessId: bid, checkCodeId: cid }
  );
}

//收款人列表
export function getPayeeList() {
  return http.get(
    productService +
      "app/businessAccount/selectListByBusinessId?businessId=" +
      businessId()
  );
}

//查询可提现门店
export function getStoreListByPayee(params) {
  params.businessId = businessId();
  params.storeIdList = params.sids.join(",");
  delete params.sids;
  return http.get(
    "boom-mix-biz-service/businessAdmin/app/businessAccount/money/canWithdraw/store",
    params
  );
}

/**
 * @description: 普通商户提现
 * @param {
 *  "businessAccountId": 0,
 * "businessId": 0,
 * "money": 0,
 * "storeList": [
 * {
 *  "storeId": 0,
 *  "storeName": "string"
 * }
 * ]
 * }
 */
export function takeMoneyByNormal(params) {
  return http.post(
    "boom-mix-biz-service/businessAdmin/app/businessAccount/withdraw/direct?businessId=" +
      businessId(),
    params
  );
}
