export function getUserAgent() {
  return navigator.userAgent.toLowerCase()
}
// 是否为微信 qq浏览器
export function isBlackApp() {
  const ua = getUserAgent()
  return /micromessenger/i.test(ua) ||  ua.indexOf("qq") > -1 	||ua.indexOf('mqqbrowser') > -1;
}

export function isWeixin(){
  const ua = getUserAgent()
  return ua.includes('micromessenger') // 微信浏览器
}

export function isMiniApp () {
  return window.__wxjs_environment === 'miniprogram'  // 微信小程序
}

// android平台
export function isAndroid() {
  const ua = navigator.userAgent
  return ua.indexOf("Android") > -1 || ua.indexOf("Linux") > -1;
}

// ios平台
export function isIos() {
  const ua = navigator.userAgent
  return !!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
} 

export function isHarmonyOS(){
  const ua = navigator.userAgent
  return ua.indexOf("ohos") > -1
}

export function openApp(url) {
  if (isIos() || isAndroid() || isHarmonyOS()) {
    try {
      const ua = getUserAgent()
      let n = ua.match(/cpu iphone os (.*?) like mac os/)
      if (((n = null !== n ? n[1].replace(/_/g, '.') : 0), parseInt(n) >= 9)) {
        // 兼容ios版本
        window.location.href = url
      } else {
        let r = document.createElement('iframe')
        r.src = url
        r.style.display = 'none'
        document.body.appendChild(r)
        window.setTimeout(function () {
          document.body.removeChild(r)
        }, 2000)
      }
    } catch (e) {
      console.log(e, 'error')
      window.location.href = url
    }
  } 
}
