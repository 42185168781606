import Vant from 'vant'
import 'vant/lib/index.css'
import '@vant/touch-emulator'

export default {
  install(app) {
    if (!app) return
    app.use(Vant)
  }
}
