import fetch from '@/plugin/axios'
import { ref, computed } from 'vue'

import { userInfoData } from '@/provider/common/account'
export const isLogin = computed(() => {
  try {
    let _userInfo = JSON.parse(localStorage['userInfo'])
    if (_userInfo) {
      userInfoData.value = _userInfo
    }
    return !!userInfoData.value?.id
  } catch (err) {
    return false
  }
})

const state = {
  // 普通用户
  userInfo: {
    creditRating: 0,
    gender: 0,
    id: '',
    inviteId: '',
    isDistributionMember: true,
    isFullMember: true,
    avatarUrl: '',
    countryCode: '',
    phoneNumber: '',
    purePhoneNumber: '',
    wxNickname: '',
    wxOpenId: '',
    authorizeWechat: true
  },
  // 商家端用户
  merchantUserInfo: {
    name: ''
  },
  authList: [],
  inviteId: '' //邀请id
}
const getters = {
  userInfo: state => state.userInfo || {},
  userId: state => state.userInfo.id,
  phone: state => state.userInfo.purePhoneNumber,
  merchantUserInfo: state => state.merchantUserInfo || {},
  merchantUserId: state => state.merchantUserInfo.id,
  isLogin: state => !!state.userInfo.id || !!state.merchantUserInfo.id,
  token: () => {
    if (localStorage.token === 'undefined' || !localStorage.token) {
      return ''
    }
    return localStorage.token
  },
  inviteId: state => state.inviteId
}
const mutations = {
  clearUserInfo: state => {
    state.userInfo = {
      id: ''
    }
    localStorage.removeItem('userInfo')
  },
  updateToken: (state, token) => {
    if (token) {
      localStorage.token = token
    }
  },
  update: (state, { key, value }) => {
    state[key] = value
  }
}
const actions = {
  getTempToken(store) {
    return fetch
      .get('/boom-center-user-service/app/members/getAnonymousToken?plantformId=3')
      .then(token => {
        localStorage.token = token
        return
      })
  },
  afterLoginSuccess({ commit, dispatch }, { userInfo, token }) {
    localStorage.setItem('userInfo', JSON.stringify(userInfo))
    commit('update', {
      key: 'userInfo',
      value: userInfo
    })
    userInfoData.value = userInfo

    commit('updateToken', token)
    dispatch('weakBind')
    return dispatch('getUserAuthList')
  },
  loginByPhoneSms({ commit, dispatch }, { phone, sms }) {
    if (!phone || !sms) {
      return Promise.reject('手机号及验证码不能为空')
    }
    return fetch
      .post('/boom-center-user-service/app/account/sub/login', {
        phone: phone,
        verifyCode: sms,
        countryCode: '86',
        loginType: 0
      })
      .then(res => {
        localStorage.setItem('userInfo', JSON.stringify(res.subAccount))
        return dispatch('afterLoginSuccess', { userInfo: res.subAccount, token: res.token })
      })
  },
  loginByToken({ commit, dispatch }, token) {
    if (token) {
      commit('updateToken', token)
    }
    return fetch.post('/account/loginByToken').then(userInfo => {
      return dispatch('afterLoginSuccess', { userInfo: userInfo })
    })
  },
  loginByMiniToken({ commit, dispatch }, token) {
    return fetch
      .post('/boom-center-user-service/app/members/convert_to_web_token', { token })
      .then(res => {
        res.memberInfo.authorizeWechat = res?.memberInfo?.isAuthorizeWechat
        return dispatch('afterLoginSuccess', { userInfo: res.memberInfo, token: res.token })
      })
  },
  loginByCode({ commit, dispatch }, { appId, code }) {
    return fetch
      .post('/boom-center-user-service/app/account/sub/login', { appId, code, loginType: 5 })
      .then(res => {
        return dispatch('afterLoginSuccess', { userInfo: res.subAccount, token: res.token })
      })
  },
  bindWx({ commit, dispatch, state }, { appId, code }) {
    return fetch
      .post('/boom-center-user-service/app/third/auth/bind/mp', {
        appId,
        code
      })
      .then(res => {
        let userInfo = state.userInfo
        userInfo.authorizeWechat = true
        return dispatch('afterLoginSuccess', { userInfo, token: localStorage.token })
      })
  },
  getUserAuthList({ commit }) {},
  logout({ commit }) {
    localStorage.removeItem('token')
    commit('clearUserInfo')
    window.location.href = '/account/login'
  },
  weakBind({ state, commit }) {
    //分销关系弱绑定
    if (state.inviteId && state.inviteId !== state.userInfo.id) {
      console.log('弱绑定：', state.inviteId)
      handleWeakBind(state.inviteId)
    }
  }
}

export function handleWeakBind(id) {
  id = id || sessionStorage['shareId'] || ''
  if (id) return fetch.put('/boom-mix-biz-service/app/distribution/weak-bind', { inviteId: id })
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
