<template>
  <div ref="product" class="product-container" :style="customStyle">
    <div class="product" @click="handleClick(data.id || data.productId)">
      <div class="img-box" :style="`background-image:url(${cover});${imgStyle}`">
        <div class="warning" v-if="data.productState === 5">即将售罄</div>
        <div class="district" v-if="data.distanceMetres > 0">
          <span>{{ data.businessDistrictTitle }}</span>
          <i>|</i>
          <!-- <span>{{ distanceFormat(data.distance) }}</span> -->
          <span>{{ distance }}</span>
        </div>
      </div>
      <div class="title">{{ title }}</div>
      <div class="info">
        <div class="price">
          <div class="now-price">
            <span>￥</span>
            <span class="num">
              {{
                data.salePrice || data.firstSkuSalePrice || data.productSkuList[0].salePrice || '--'
              }}
            </span>
          </div>
          <div class="old-price" v-if="data.originalPrice || data.firstSkuOriginalPrice">
            ￥{{ data.originalPrice || data.firstSkuOriginalPrice || '--' }}
          </div>
        </div>
        <div class="discount" v-if="discount && discount < 10">
          {{ discount < 0.01 ? 0.01 : discount }}折
        </div>
        <div class="sold" v-if="sold">已售{{ sold }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import productBrowswTrack from './productBrowseTrack'

export default {
  mixins: [productBrowswTrack],
  props: {
    data: {
      type: Object,
      default: {}
    },
    imgStyle: {
      type: String,
      default: ''
    },
    customStyle: {
      type: String,
      default: ''
    },
    source: {
      type: Number,
      value: 1
    }
  },
  computed: {
    title() {
      let name = this.data.title || this.data.productName || this.data.productSkuName
      if (name.length >= 22) {
        return name.slice(0, 22) + '...'
      }
      return name
    },
    sold() {
      let data = this.data
      try {
        return data.saleCount || data.soldNum || data.productSkuList[0].saleNum
      } catch (err) {
        return 0
      }
    },
    cover() {
      let data = this.data
      let url = data.url || data.productUrl || data.productImageUrl
      if (!url.includes('x-oss-process=style/gif')) {
        url += '?x-oss-process=style/thumb_600'
      }
      return url
    },
    distance() {
      if (this.data.distanceMetres < 1000) {
        return Math.round(this.data.distanceMetres) + 'm'
      } else {
        return (this.data.distanceMetres / 1000).toFixed(2) + 'km'
      }
    },
    discount() {
      let data = this.data
      return (
        ((data.salePrice || data.firstSkuSalePrice) /
          (data.originalPrice || data.firstSkuOriginalPrice)) *
        10
      ).toFixed(1)
    }
  },
  methods: {
    // distanceFormat(distance) {
    //   if (distance >= 0 && distance < 100) {
    //     return '<100m'
    //   } else if (distance >= 100 && distance < 300) {
    //     return '<300m'
    //   } else if (distance >= 300 && distance < 500) {
    //     return '<500m'
    //   } else if (distance >= 500 && distance < 1000) {
    //     return '<1000m'
    //   } else {
    //     return Number(parseFloat(distance / 1000).toFixed(2)) + 'km'
    //   }
    // },
    handleClick(id) {
      this.$router.push(`/product/${id}/detail?source=${this.source}`)
    }
  }
}
</script>

<style lang="less" scoped>
.product-container {
  position: relative;
  width: calc(50% - 5px);
  break-inside: avoid;
  overflow: hidden;
}
.product {
  width: 100%;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 5px;
  margin-bottom: 10px;
  overflow: hidden;
  &:active {
    filter: brightness(95%);
  }
  .img-box {
    width: 100%;
    height: 124px;
    border-radius: 12rpx 12rpx;
    margin-bottom: 10rpx;
    background-position: center center;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    position: relative;
    overflow: hidden;
    .warning {
      position: absolute;
      width: 75px;
      z-index: 2;
      top: 5px;
      left: 5px;
      color: #fff;
      text-align: center;
      font-size: 14px;
      padding: 2.5px 10px;
      border-radius: 0 5px 0 0;
      white-space: nowrap;
      background: linear-gradient(to right, rgba(231, 20, 26, 0.7), rgba(255, 128, 132, 0.7));
    }
  }
  .title {
    width: 100%;
    height: 37px;
    margin-top: 10px;
    padding: 0 10px;
    font-size: 14px;
    color: #333;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    -moz-line-clamp: 2;
    -ms-line-clamp: 2;
    -o-line-clamp: 2;
    line-clamp: 2;
    overflow: hidden;
    word-break: break-all;
    text-overflow: ellipsis;
  }
  .info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    .price {
      // display: flex;
      // align-items: center;
      overflow: hidden;
      font-size: 12px;
      .now-price {
        font-weight: bold;
        color: #e7141a;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .old-price {
        flex: 1;
        margin-left: 2.5px;
        overflow: hidden;
        text-decoration: line-through;
        font-size: 12px;
        color: #999;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
    .discount {
      position: relative;
      border: 1px solid red;
      font-size: 11px;
      color: red;
      height: fit-content;
      border-radius: 30px;
      padding: 0px 5px;
      margin-left: 15px;
    }
    .sold {
      flex: 1;
      text-align: right;
      font-size: 10px;
      color: #999999;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}
.district {
  position: absolute;
  bottom: 0px;
  left: 5px;
  font-size: 12px;
  padding: 2.5px 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-radius: 5px 5px 0 0;
  background: rgba(0, 0, 0, 0.3);
  color: #fff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  i {
    padding: 0 5px;
  }
}
.districtName {
  font-size: 14px;
  color: #333;
  padding: 0 10px;
}
</style>
