<template>
  <div :class="`slide-panel-container ${show ? 'show' : 'hide'}`" ref="slidePanelContainer">
    <div class="mask" v-if="overlay" @click="$emit('update:show', false)"></div>
    <div class="container" :style="`max-height:${maxHeight}`">
      <div
        :class="`slide-panel`"
        ref="slidePanel"
        :style="`${styles};`"
        @click.stop="() => {}"
        @touchstart="onTouchStart"
        @touchmove.prevent="onTouchMove"
        @touchend="onTouchEnd"
      >
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
let pos = { y: 0 }
let distance = 0
export default {
  name: 'SlidePanel',
  props: {
    appendToBody: {
      type: Boolean,
      default: false
    },
    height: {
      type: String,
      default: '80vh'
    },
    show: {
      type: Boolean,
      default: false
    },
    styles: {
      type: String,
      default: ''
    },
    maxHeight: {
      type: String,
      default: '80vh'
    },
    overlay: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {}
  },
  watch: {
    $route(route) {
      if (route.hash != '#slidePanel' && this.show) {
        this.$emit('update:show', false)
      }
    },
    show(v) {
      if (v) {
        window.open('#slidePanel', '_self')
        this.$refs.slidePanel.style.bottom = 0 + 'px'
      } else {
        this.$refs.slidePanel.style.bottom = '-100%'
        if (this.$route.hash == '#slidePanel') {
          history.back()
        }
      }
    }
  },
  mounted() {
    if (this.appendToBody) {
      document.body.appendChild(this.$refs.slidePanelContainer)
    }
  },
  methods: {
    onSwipeLeft() {},
    onTouchStart(e) {
      this.$refs.slidePanel.style.transitionDuration = '0s'
      if (!pos.y) {
        pos = { y: e.changedTouches[0].clientY }
      }
    },
    onTouchMove(e) {
      distance = e.changedTouches[0].clientY - pos.y
      // return
      let bottom = parseInt(this.$refs.slidePanel.style.bottom.split('px')) || 0
      if (parseInt(this.$refs.slidePanel.style.bottom.split('px')) < 0) {
      }
      console.log(bottom, distance)
      console.log(bottom + distance)
      this.$refs.slidePanel.style.bottom = bottom + distance + 'px'
    },
    onTouchEnd(e) {
      if (parseInt(this.$refs.slidePanel.style.bottom.split('px')) < 0) {
        this.$refs.slidePanel.style.transitionDuration = '.5s'
        this.$refs.slidePanel.style.bottom = -distance + 'px'
      }
      // pos.y += 50
      // if (distance > 0) {
      //   distance += 50
      // } else {
      //   distance -= 50
      // }

      return
      if (distance < 150) {
        this.$refs.slidePanel.style.bottom = 0 + 'px'
      } else {
        this.$emit('update:show', false)
      }
    }
  }
}
</script>

<style lang='less' scoped>
.slide-panel-container {
  position: fixed;
  transition: all 0.5s;
  z-index: 99999;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  &.show {
    bottom: 0;
  }
  &.hide {
    bottom: -100%;
  }
  .mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 99998;
    background: rgba(0, 0, 0, 0.2);
  }
  .container {
    position: absolute;
    z-index: 99999;
    bottom: 0;
    left: 0;
    width: 100vw;
    overflow: hidden;
    border-radius: 18px 18px 0 0;
  }
  .slide-panel {
    position: relative;
    transition: all 0.5s;
    height: fit-content;
    background: #fff;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    border-radius: 18px 18px 0 0;
    overflow: auto;
    bottom: 0;
    .line {
      position: sticky;
      z-index: 9;
      width: 40px;
      height: 4px;
      background: #e0e0e0;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      border-radius: 30px;
    }
    .content {
    }
  }
}
</style>
