import http from "@/plugin/axios";

const productService = "boom-center-product-service/app/";
const mixService = "boom-mix-biz-service/app/";
const adminService = "boom-center-admin-service/app/";
const productServiceBase = "boom-center-product-service/"

//首页商品组
export function productGroupList() {
  return http.get(mixService + "productGroup/list");
}
export function productGroupContent(id) {
  return http.get(`/boom-center-admin-service/app/productGroup/${id}`);
}

/**
 * @description: 首页各组件数据
 * @param {
 *  modelType：banner为轮播图，zone为专区，popUps为弹窗，horizontalNavigation为水平导航栏
 * }
 */
export function homeComponent(modelType) {
  return http.get(mixService + `decoration/${modelType}`);
}

//魔方组件
export function getCube(id) {
  return http.get(adminService + `decoration/magicCube/${id}`);
}

//底部tab
export function bottomTab() {
  return http.get(adminService + "decoration/bottomBar");
}

//发现页
export function findPage() {
  return http.get(adminService + "decoration/discovery");
}

export function homeMoudle() {
  return http.get(adminService + "decoration/module");
}

export function homeSeckill() {
  return http.get(mixService + "limitedTimeSaleActivity/home");
}


// 轮播图大小
export function getBannerSize(params) {
  return http.get(adminService + `decoration/bannerSize`, { params })
}
// 专区分类
export function getActiveContentCategoryTree(params) {
  return http.get(productServiceBase + 'product/productGroup/categoryAndDistrict/tree', { params })
}
