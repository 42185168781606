import fetch from '@/plugin/axios'
import { Notify } from 'vant'
import userTrack from '@/util/userTrack'
import { formatDate } from '@/util/util'

function specialTimeFormat(specialTime) {
  let week, date, time

  if (specialTime.specifiedTimeType == 2) {
    let weekList = ['', '周一', '周二', '周三', '周四', '周五', '周六', '周日']
    let startWeekDay = weekList[specialTime.startWeek] || ''
    let endWeekDay = weekList[specialTime.endWeek] || ''
    if (startWeekDay == endWeekDay) endWeekDay = ''
    let x = startWeekDay && endWeekDay ? '至' : ''
    week = startWeekDay + x + endWeekDay
  } else if (specialTime.specifiedTimeType == 3) {
    let startDate = formatDate(Number(specialTime.startDate)) || ''
    let endDate = formatDate(Number(specialTime.endDate)) || ''
    if (startDate == endDate) endDate = ''
    let x1 = startDate && endDate ? '至' : ''
    date = startDate + x1 + endDate
  }
  time = specialTime.startTime.substring(0, 5) + '-' + specialTime.endTime.substring(0, 5)
  return {
    ...specialTime,
    week,
    date,
    time
  }
}

const state = {
  productDetail: {
    businessId: '',
    businessLogo: '',
    businessName: '',
    buyLimit: 0,
    collectFlag: false,
    distributionAmountDisplayFlag: false,
    maxDistributionAmount: 0,
    minDistributionAmount: 0,
    originalPrice: 0,
    posterQrCodeSet: 1,
    productDescription: '',
    productDetailUrl: '',
    productName: '',
    productState: 0,
    purchaseInstructionsUrl: '',
    refundType: 0,
    residueCount: 0,
    viewCount: 0,
    saleCount: 0,
    salePrice: 0,
    subMchidType: 0,
    switchSectionStock: 0,
    categoryIds: [],
    packSku: false,
    notes: [],
    skuList: [],
    skuPackageList: [],
    imageUrlList: [],
    writeOffTimeEnd: 0,
    writeOffTimeStart: 0,
    tenantId: 0,
    areaIds: []
  },
  productDistributionInfo: {
    distributionAmountDisplayFlag: false,
    maxDistributionAmount: 0,
    minDistributionAmount: 0,
    tenantId: 0
  },
  recommend: []
}
const getters = {
  productDetail: state => state.productDetail,
  productDistributionInfo: state => state.productDistributionInfo,
  recommend: state => state.recommend
}
const mutations = {
  update: (state, { key, value }) => (state[key] = value)
}
const actions = {
  async getProductDetail(store, productId) {
    return new Promise(async (resolve, reject) => {
      if (!productId) {
        reject({ msg: '参数错误' })
        return
      }
      try {
        store.commit('update', {
          key: 'productDetail',
          value: {}
        })
        store.commit('common/sys/loadingStart', {}, { root: true })
        console.log('productId >>>>>>>>>>>>', productId)

        let productInfo = await fetch.get(`boom-center-product-service/product/${productId}/v2`, {
          isAreaLess: true
        })
        document.title = productInfo.productName
        productInfo.purchaseInstructions = productInfo.purchaseInstructions.replace(/\<img style=/gi, '<img ')
        productInfo.purchaseInstructions = productInfo.purchaseInstructions.replace(
          /\<img/gi,
          '<img style="max-width:100%;height:auto"'
        )
        productInfo.productDetailContent = productInfo.productDetailContent.replace(/\<img style=/gi, '<img ')
        productInfo.productDetailContent = productInfo.productDetailContent.replace(
          /\<img/gi,
          '<img style="max-width:100%;height:auto"'
        )
        productInfo.checkStart = productInfo.writeOffTimeStart
        productInfo.checkEnd = productInfo.writeOffTimeEnd

        // 展示今日不可用
        productInfo.isTodayUse = false
        let curDay = new Date().getDay() || 7
        let curTime = Date.now()
        console.log('今天星期几啊===》', curDay)
        console.log('现在时间戳===》', curTime)
        // 可用时间
        if (productInfo.timeSlotType == 2) {
          if (productInfo.specifiedTimeList.length > 0) {
            productInfo.specifiedTimeList = productInfo.specifiedTimeList.map(item => {
              return specialTimeFormat(item)
            })
          }
          if (productInfo.timeSlotList.length > 0) {
            productInfo.canuse = productInfo.timeSlotList.map(i => `${i.startTime}-${i.endTime}`)
          }
        }
        productInfo.skuDetailList = productInfo.skuDetailList.filter(
          item => item.detailPartList?.length && (item.displayStatus || item.id == 0)
        )
        if (productInfo.skuDetailList.length > 1) {
          productInfo.skuDetailList = productInfo.skuDetailList.filter(item => item.id != 0)
        }
        productInfo.skuDetailList.map(item => {
          let _sku = productInfo.skuList.find(sku => sku.id == item.id)
          item.skuName = _sku?.skuName
        })
        // 不可用时间
        if (productInfo.unavailableDateType == 2) {
          let weekArr = ['周一', '周二', '周三', '周四', '周五', '周六', '周日']
          if (productInfo.unavailableWeekList.length) {
            productInfo.weekUse = productInfo.unavailableWeekList
              .sort((a, b) => a - b)
              .map(i => `${weekArr[i - 1]}`)
              .join(',')
            if (productInfo.unavailableWeekList.indexOf(curDay) > -1) {
              productInfo.isTodayUse = true
            }
          }
          if (productInfo.unavailableDateList.length) {
            let timeUse = []
            let specialUse = []
            productInfo.unavailableDateList.forEach((item, index) => {
              if (item.type == 1) {
                specialUse.push(
                  `${item.specialDateName || '法定节假日'}(${formatDate(item.startDate)}~${formatDate(item.endDate)})`
                )
              } else if (item.type == 2) {
                specialUse.push(`${item.specialDateName}(${formatDate(item.startDate)}~${formatDate(item.endDate)})`)
              } else if (item.type == 3) {
                timeUse.push(`${formatDate(item.startDate)}~${formatDate(item.endDate)}`)
              }
              if (curTime >= item.startDate && curTime <= item.endDate) {
                productInfo.isTodayUse = true
              }
            })
            productInfo.timeUse = timeUse
            productInfo.specialUse = specialUse
          }
        }

        productInfo.limitedTimeSale = productInfo.secKill == 1
        let res = productInfo
        if (res.type == 2) {
          res.saleType = 5
        } else if (res.type == 3) {
          res.saleType = 6
        } else {
          if (res.checkCodeSource == 1) {
            res.saleType = 1
            if (res.secKill == 1) {
              res.saleType = 4
            }
          } else if (res.checkCodeSource == 2) {
            res.saleType = 2
          } else if (res.checkCodeSource == 0) {
            res.saleType = 3
          }
        }

        productInfo.id = productId
        if (parseInt(productInfo.writeOffTimeStart) > new Date().getTime()) {
          productInfo.writeOffTimeStart = formatDate(parseInt(productInfo.writeOffTimeStart))
          productInfo.writeOffTimeEnd = formatDate(parseInt(productInfo.writeOffTimeEnd))
        } else {
          productInfo.writeOffTimeStart = ''
        }
        store.commit('update', {
          key: 'productDetail',
          value: productInfo
        })

        const distributionInfo = await fetch.get(
          `/boom-center-search-service/product/distributionAmount/${productId}`,
          {
            params: {
              productId: productInfo.id
            }
          }
        )
        store.commit('update', {
          key: 'productDistributionInfo',
          value: distributionInfo
        })

        const recommend = await fetch.post('/boom-center-product-service/product/recommendCommonCategoryProduct', {
          categoryId: productInfo.categoryIdList,
          productId
        })
        localStorage['recommendForProduct'] = JSON.stringify(recommend)
        store.commit('update', {
          key: 'recommend',
          value: recommend
        })

        userTrack.productDetail(productId)
        resolve()
      } catch (err) {
        Notify({ type: 'warning', message: err.msg || err.message })
        reject()
      } finally {
        store.commit('common/sys/loadingStop', {}, { root: true })
      }
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
