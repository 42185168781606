import fetch from '@/plugin/axios'

const state = {
  foo: 'bar'
}
const getters = {
  foo: state => state.foo
}
const mutations = {
  update: (state, { key, value }) => (state[key] = value)
}
const actions = {
  initConfig({}, { url, apiList }) {
    fetch.post('/account/getWorkWxSignature', { url }).then(res => {
      wx.config({
        ...res,
        debug: false,
        jsApiList: apiList
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
