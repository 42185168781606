import http from "@/plugin/axios";

const st = "boom-center-statistics-service/";
const mixService = "boom-mix-biz-service/";
export function getAllRank({ page = 1, size = 10 }) {
  return http.get(
    st + `rankStatistics/store/rank/get?page=${page}&size=${size}`
  );
}

export function getRecommandRank({ page = 1, size = 10 }) {
  return http.get(
    mixService + `app/businessEsurient?page=${page}&size=${size}`
  );
}

export function getProductView(id) {
  return http.get(
    st + `app/trace-statistics/product-user-view/${id}`,
    {},
    { "Bm-Area-Id": -1 }
  );
}

//商品浏览数
export function getPv({ code, id }) {
  return http.get(
    st + `app/trace-statistics/pv?code=${code}&payloadId=${id}`,
    {},
    { "Bm-Area-Id": -1 }
  );
}

//最近浏览
export function getRecentView(id) {
  return http.get(
    st + `app/trace-statistics/product/watching/${id}`,
    {},
    { "Bm-Area-Id": -1 }
  );
}
