import fetch from '@/plugin/axios'
import { ref } from 'vue'
import isMobile from 'is-mobile'
import { Dialog } from 'vant'
import { isMiniApp, isWeixin } from '@/util/sys'
export let location = ref({})
export let area = ref({
  id: '',
  name: ''
})

const state = {
  colorList: ['#ffa502', '#ff6348', '#ff4757', '#2ed573', '#1e90ff'],
  anonymousApiList: ['getAnonymousToken'],
  currentTenantId: process.env.VUE_APP_tenantId,
  currentArea: {
    id: '',
    name: ''
  },
  loading: false,
  updateTitle: false,
  location: {
    isLocate: false, //是否已经定位
    locating: false, //定位中
    lat: 23.099715,
    lon: 113.370972,
    areaId: '',
    areaName: ''
  },
  isShare: false,
  mallSetting: {}
}
const getters = {
  loading: state => state.loading,
  isDebug: () => {
    return window.location.href.includes('isDebug=true')
  },
  isDev: () => process.env.NODE_ENV === 'development',
  randomColor: state => () => state.colorList[Math.floor(Math.random() * state.colorList.length)],
  today: () => new Date().toISOString().substr(0, 10),
  isBmMiniApp: () =>
    window.__wxjs_environment === 'miniprogram' ||
    /miniProgram/i.test(navigator.userAgent.toLowerCase()),
  isWx: () => {
    let ua = navigator.userAgent.toLowerCase()
    if (ua.match(/MicroMessenger/i) == 'micromessenger') {
      return true
    }
    return false
  },
  isSafari: () => {
    let ua = navigator.userAgent.toLowerCase()
    return ua.includes('safari') && ua.includes('iphone')
  },
  anonymousApiList: state => state.anonymousApiList,
  isAnonymousApi: state => url => {
    if (url) {
      return state.anonymousApiList.some(keyword => url.includes(keyword))
    }
    return false
  },
  currentTenantId: state => {
    return process.env.VUE_APP_tenantId || 1
  },
  currentAreaId: state => state.currentArea.id || -1,
  currentArea: state => state.currentArea.name || '',
  isMobile: () => isMobile(navigator.userAgent) && window.innerWidth < 800,
  updateTitle: state => state.updateTitle,
  location: state => state.location,
  isShare: state => state.isShare,
  mallSetting: state => state.mallSetting
}
const mutations = {
  loadingStart: state => {
    state.loading = true
  },
  loadingStop: state => (state.loading = false),
  update: (state, { key, value }) => {
    state[key] = value
    if (key === 'currentArea') {
      localStorage['areaId'] = value.id
    }
  },
  updateLocation: (state, location) => {}
}

const actions = {
  getAreaList(store) {},
  clearCache() {
    localStorage.clear()
    sessionStorage.clear()
  },

  // 高德地图定位
  // getLocation({ state, commit, dispatch }) {
  //   try {
  //     location.value = JSON.parse(localStorage['location'])
  //   } catch (err) {}
  //   return new Promise((resolve, reject) => {
  //     console.info('>>>>>>> start amap')
  //     navigator.geolocation.getCurrentPosition(position => {
  //       console.log('>>>>>>> getCurrentPosition', position)
  //     })
  //     state.location.locating = true

  //     if (!AMap) {
  //       dispatch('getAreaByLocation', { isLocate: false })
  //         .then(() => {
  //           resolve()
  //         })
  //         .catch(() => reject())
  //     } else {
  //       AMap.plugin('AMap.Geolocation', () => {
  //         const geolocation = new AMap.Geolocation({
  //           enableHighAccuracy: true,
  //           timeout: 10000
  //         })
  //         geolocation.getCurrentPosition()
  //         AMap.event.addListener(geolocation, 'complete', result => {
  //           if (result?.type === 'complete') {
  //             console.log('>>>>>>> result', result)
  //             let city = ''
  //             if (result?.addressComponent?.city.length) {
  //               city = result.addressComponent.city
  //             } else if (result?.addressComponent?.province.length) {
  //               city = result.addressComponent.province
  //             }
  //             city = city.replace('市', '')
  //             dispatch('getAreaByLocation', {
  //               lat: result.position.lat,
  //               lng: result.position.lng,
  //               province: result?.addressComponent?.province,
  //               city: city,
  //               district: result?.addressComponent?.district,
  //               isLocate: true
  //             })
  //               .then(() => {
  //                 resolve()
  //               })
  //               .catch(() => reject())
  //           } else {
  //             console.log('>>>>>>> error', result)
  //             reject(result)
  //           }
  //         })
  //         AMap.event.addListener(geolocation, 'error', error => {
  //           console.log('>>>>>>> error', error)
  //           dispatch('getAreaByLocation', { city: '广州', isLocate: false })
  //             .then(() => {
  //               resolve()
  //             })
  //             .catch(() => reject())
  //         })
  //       })
  //     }
  //   })
  // },

  // 腾讯地图定位
  getLocation({ state, commit, dispatch }) {
    try {
      location.value = JSON.parse(localStorage['location'])
    } catch (err) {}
    return new Promise((resolve, reject) => {
      console.info('>>>>>>> start amap')
      navigator.geolocation.getCurrentPosition(position => {
        console.log('>>>>>>> getCurrentPosition', position)
      })
      state.location.locating = true

      // 如果在微信小程序内
      if (isWeixin() || isMiniApp()) {
        let appid = 'wx5d2e232ce5454554'
        // let appid = 'wx4c71feaac422f7d9'
        if (window.location.href.includes('h5.mall.tanchi.shop')) {
          appid = 'wx4c71feaac422f7d9'
        }
        fetch
          .post('/boom-center-product-service/wechat/createJsapiSignature', {
            appId: appid,
            url: window.location.href
          })
          .then(res => {
            wx.config({
              debug: false,
              appId: res.appId, // 必填，公众号的唯一标识
              timestamp: res.timestamp, // 必填，生成签名的时间戳
              nonceStr: res.nonceStr, // 必填，生成签名的随机串
              signature: res.signature, // 必填，签名
              jsApiList: [
                'chooseWXPay',
                'getLocation',
                'updateTimelineShareData',
                'updateAppMessageShareData'
              ] // 必填，需要使用的JS接口列表
            })
            // 如果准备好了
            wx.ready(() => {
              wx.getLocation({
                type: 'wgs84', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
                success: function (res) {
                  dispatch('getAreaByLocation', {
                    lat: res.latitude,
                    lng: res.longitude,
                    isLocate: true
                  })
                    .then(() => {
                      resolve()
                    })
                    .catch(() => reject())
                },
                cancel: function (res) {
                  console.log('未授权位置获取请求')
                },
                fail: function (err) {
                  console.log(JSON.stringify(err)) //开发者工具每次都是进到fail里，但是手机每次都是执行success里的代码，没办法调试下去了
                }
              })
            })
          })
      }
      // 如果没有腾讯地图
      else if (!qq?.maps?.Geolocation) {
        dispatch('getAreaByLocation', { isLocate: false })
          .then(() => {
            resolve()
          })
          .catch(() => reject())
      }
      // 如果有腾讯地图
      else {
        var geolocation = new qq.maps.Geolocation('SFTBZ-YB5K5-QLBIO-IO5V7-AUEG5-5VBYH', 'myapp')
        geolocation.getLocation(showPosition, showErr, options)
        var options = { timeout: 8000 }
        function showPosition(position) {
          console.log('定位成功')
          let city = ''
          if (position.city) {
            city = position.city
          } else if (position.province) {
            city = position.province
          }
          city = city.replace('市', '')
          dispatch('getAreaByLocation', {
            lat: position.lat,
            lng: position.lng,
            province: position?.province,
            city: city,
            district: position?.district,
            isLocate: true
          })
            .then(() => {
              resolve()
            })
            .catch(() => reject())
        }

        function showErr() {
          console.log('定位失败')
          dispatch('getAreaByLocation', { city: '广州', isLocate: false })
            .then(() => {
              resolve()
            })
            .catch(() => reject())
        }
      }
    })
  },

  getAreaByLocation(
    { state, commit },
    { lat = 23.099715, lng = 113.370972, province = '', district = '', city = '', isLocate = false }
  ) {
    console.log('lat = ', lat, 'lng =', lng, 'city =', city, 'isLocate =', isLocate)
    return new Promise((resolve, reject) => {
      fetch
        .get(`/boom-center-admin-service/app/area-infos/current-location/v1`, {
          params: { latitude: lat, longitude: lng }
        })
        .then(res => {
          if (!localStorage['areaId']) {
            localStorage['areaId'] = res.id
            commit('update', {
              key: 'currentArea',
              value: {
                id: res.id,
                name: res.areaName
              }
            })
            area.value = {
              id: res.id,
              name: res.areaName
            }
          } else {
            if (localStorage['areaId'] != res.id && isLocate) {
              Dialog.confirm({
                title: `当前定位在-${res.areaName}`,
                message: '是否切换'
              })
                .then(() => {
                  localStorage['areaId'] = res.id
                  commit('update', {
                    key: 'currentArea',
                    value: {
                      id: res.id,
                      name: res.areaName
                    }
                  })
                  area.value = {
                    id: res.id,
                    name: res.areaName
                  }
                })
                .catch(() => {})
            }
          }
          let _location = {
            isLocate,
            locating: false,
            province,
            city,
            district,
            lat: lat,
            lon: lng,
            lng,
            areaId: res.id,
            areaName: res.areaName
          }
          commit('update', {
            key: 'location',
            value: _location
          })
          localStorage.location = JSON.stringify(_location)
          location.value = _location
          resolve()
        })
        .catch(() => {
          reject()
        })
    })
  },
  getMallSetting(store) {
    return fetch.get('/boom-center-admin-service/app/mallSetting').then(res => {
      store.commit('update', { key: 'mallSetting', value: res })
    })
  }
}
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
