<template>
  <div class="comment">
    <img class="avatar" :src="`${data.anonymous ? iconUrl + 'logo.png' : data.memberIcon}`" />
    <div class="right">
      <div class="name">{{ data.anonymous ? '匿名用户' : data.memberNickname }}</div>
      <div class="time">{{ formatFullDate(data.createTime) }}</div>
      <div class="rate">
        <van-rate
          :modelValue="data.allStar"
          size="15px"
          readonly
          :void-icon="`${iconUrl}star_normal.png`"
          :icon="`${iconUrl}star_selected.png`"
        />
        <div class="tag" v-if="data.orderId && data.orderId !== '' && data.orderId !== '0'">
          消费后评分
        </div>
      </div>
      <div class="skuname" bindtap="jumpProduct">
        {{ data.skuTitle || data.productName }}
      </div>
      <div class="content">
        {{ data.content }}
      </div>
      <div class="imgs">
        <div class="img-box" v-for="(item, index) in data.reviewPhotoList || data.reviewPhoto">
          <img
            class="img"
            :src="`${item}?x-oss-process=style/thumb_600`"
            @click="previewImg(index)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { formatFullDate } from '@/util/filter'
</script>

<script>
export default {
  props: ['data'],
  data() {
    return {
      iconUrl: process.env.VUE_APP_baseStaticUrl + 'icon/common/'
    }
  },
  methods: {
    previewImg(index) {
      this.$imagePreview({
        images: this.data.reviewPhotoList || this.data.reviewPhoto,
        index: index
      })
    }
  }
}
</script>

<style lang="less" scoped>
.comment {
  padding: 15px !important;
  display: flex;
  .avatar {
    width: 40px;
    height: 40px;
    margin-right: 15px;
    border-radius: 50%;
    overflow: hidden;
  }
  .right {
    overflow: hidden;
    flex: 1;
    .name {
      font-size: 15px;
      color: #333;
    }
    .time {
      font-size: 11px;
      color: #999999;
      margin-top: 2px;
    }
    .rate {
      font-size: 11px;
      color: #999999;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .tag {
        float: right;
        border: 1px solid #e7141a;
        border-radius: 0px;
        font-size: 8px;
        color: #e7141a;
        padding: 2px 5px;
      }
    }
    .skuname {
      font-size: 11px;
      color: #003366;
      margin: 5px 0;
    }
    .content {
      font-size: 15px;
      color: #333333;
    }
    .imgs {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      .img-box {
        width: 33%;
        height: 0;
        padding-bottom: 33%;
        overflow: hidden;
        position: relative;
        .img {
          width: 100%;
          height: 90px;
          margin: 5px;
          object-fit: cover;
          &:active {
            filter: brightness(95%);
          }
        }
      }
    }
    .bottom {
      display: flex;
      align-items: center;
      margin-top: 15px;
      justify-content: space-between;
      .icon {
        width: 20px;
        height: 20px;
      }
      .liked {
        flex: 1;
        overflow: hidden;
        position: relative;
        transform: translateX(10px);
        font-size: 11px;
        color: #999999;
        display: flex;
        align-items: center;
        .user {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          position: relative;
        }
        .num {
          position: relative;
          transform: translateX(-10px);
        }
      }
      .action {
        display: flex;
        align-items: center;
        .btn {
          display: inline-block;
          font-size: 11px;
          color: #999999;
          display: flex;
          align-items: center;
          margin-right: 15px;
          &:active {
            filter: brightness(9%);
          }
        }
      }
    }
  }
}
</style>
