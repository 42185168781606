import axios from 'axios'
import { logAxiosSuccess, logAxiosError } from '@/util/log'
import { isMicroApp, isDev } from '@/util/env'
import $store from '@/plugin/store'

let config = {
  baseURL: process.env.VUE_APP_apiBaseUrl || '',
  timeout: 60 * 1000,
  withCredentials: false
}

let instance = axios.create(config)

instance.interceptors.request.use(
  async config => {
    if (!localStorage.token && !$store.getters['common/sys/isAnonymousApi'](config.url)) {
      await $store.dispatch('common/account/getTempToken')
    }
    if (localStorage.token) {
      config.headers['Bm-Member-Token'] = localStorage.token
    }
    config.headers['Request-Source'] = 'h5'
    config.headers['Tenant-Id'] =
      config.headers['Tenant-Id'] || $store.getters['common/sys/currentTenantId']
    if (config.isAreaLess) {
      config.headers['Bm-Area-Id'] = -1
    } else {
      config.headers['Bm-Area-Id'] =
        config.headers['Bm-Area-Id'] ||
        $store.getters['common/sys/currentAreaId'] ||
        localStorage.areaId ||
        -1
    }
    if (config.params) {
      Reflect.deleteProperty(config.params, 'total')
      Reflect.deleteProperty(config.params, 'isFinish')
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

instance.interceptors.response.use(
  response => {
    if (response.headers['bm-new-token']) {
      localStorage.token = response.headers['bm-new-token']
    }
    if (response.headers['new-bm-member-token']) {
      localStorage.token = response.headers['new-bm-member-token']
    }
    if (response.data.code === 0 || response.data.showApiResCode === 0) {
      logAxiosSuccess(response)
      if (response.data.list) {
        let page = response.data.page
        if (page) {
          page.isFinish = page.page * page.size >= page.total
        }
        return { page, list: response.data.list }
      }
      return response.data.data || response.data
    } else if ([2000, 2001, 2003, 2004].includes(response.data.code)) {
      console.log('>>>>>>> token error')
      sessionStorage['loginBackPath'] = location.pathname
      let path = location.pathname
      if (
        (path.includes('product') && path.includes('detail')) ||
        path.includes('home') ||
        path.includes('classify') ||
        path.includes('nearby') ||
        path.includes('webview')
      ) {
        localStorage.removeItem('token')
        $store.commit('common/account/clearUserInfo')
        location.reload()
      } else {
        $store.dispatch('common/account/logout')
      }
    } else if ([2999, 2006].includes(response.data.code)) {
      alert(response.data.msg)
      $store.commit('common/account/clearUserInfo')
      location.reload()
    } else {
      if (!response.config.url.includes('bind_invite')) {
      }
      logAxiosError(response)
      return Promise.reject(response.data)
    }
  },
  error => {
    return Promise.reject(error)
  }
)

window.$mainAxios = instance

export default instance
