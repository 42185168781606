import fetch from '@/plugin/axios'

const state = {}
const getters = {
  ua: () => navigator.userAgent.toLowerCase(),
  isIOSApp: (state, getters) => getters.ua.includes('isboomiosapp'),
  isAndroidApp: (state, getters) => getters.ua.includes('isboomandroidapp'),
  isApp: (state, getters) => getters.isIOSApp || getters.isAndroidApp,
  boomRequestSource: (state, getters) => {}
}
const mutations = {}
const actions = {
  bridgeRouter(store, { pageId, payload }) {
    if (store.getters.isIOSApp) {
      window.webkit.messageHandlers.router.postMessage(JSON.stringify({ pageId, payload }))
    } else if (store.getters.isAndroidApp) {
      window.boom.bridgeRouter(JSON.stringify({ pageId, payload }))
    } else {
      console.log('>>>>>>> client error')
    }
  },
  requestPayment(store, payload) {
    if (store.getters.isIOSApp) {
      window.webkit.messageHandlers.requestPayment.postMessage(JSON.stringify(payload))
    } else if (store.getters.isAndroidApp) {
      window.boom.requestPayment(JSON.stringify(payload))
    } else {
      console.log('>>>>>>> client error')
    }
  }
}
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
