<template>
  <div v-if="!isShowTopBar && !$route.meta.hideTopbar" class="topBarContainer" :class="theme">
    <div @click="handleGoBack" class="icon">
      <van-icon name="arrow-left" size="16px" />
    </div>
    <div class="title">{{ title }}</div>
  </div>
</template>

<script>
export default {
  props: {
    theme: {
      type: String,
      default: 'default'
    }
  },
  data: () => ({
    title: ''
  }),
  computed: {
    isShowTopBar() {
      return this.$store.getters['common/sys/isBmMiniApp']
    },
    isShowBackBtn() {
      return window.history.length > 2
    },
    updateTitle() {
      return this.$store.getters['common/sys/updateTitle']
    }
  },
  watch: {
    '$route.meta'() {
      this.title = this.$route.meta.title || '贪吃商城'
    },
    updateTitle(v) {
      if (v) {
        this.title = this.$route.meta.title || '贪吃商城'
        this.$store.commit('common/sys/update', { key: 'updateTitle', value: false })
      }
    }
  },
  mounted() {
    console.log(this.$route)
    this.title = this.$route.meta.title || '贪吃商城'
  },
  methods: {
    handleGoBack() {
      if (window.history.length > 1) {
        this.$router.back()
      } else {
        this.$router.push('/home')
      }
    }
  }
}
</script>

<style lang="less" scoped>
.topBarContainer {
  position: sticky;
  top: 0;
  left: 0;
  z-index: @tabBarIndex;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 15px;
  margin: 0;
  height: @headerBarHeight;
  .icon {
    display: flex;
  }
  .title {
    font-size: 20px;
    font-weight: bold;
    flex: 1;
    text-align: center;
  }
  &.default {
    background: white;
  }
  &.dark {
    background: black;
  }
}
</style>
