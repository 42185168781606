import http from "@/plugin/axios";

const productService = "boom-center-product-service/app/";
const mixService = "boom-mix-biz-service/app/";

//活动列表
export function getList(page = 1) {
  return http.get(productService + "overlordMeal/findPage", { page, size: 10 });
}

//活动详情
export function getDetail(id) {
  return http.get(
    productService + `overlordMeal/${id}`,
    {},
    { "Bm-Area-Id": -1 }
  );
}

/**
 * @description: 参加霸王餐活动
 * @param {
 * inviteId 邀请人id
 * overlordMealId 活动id
 * }
 */
export function join(params) {
  return http.post(mixService + `overlordMeal/participate`, params, {
    "Bm-Area-Id": -1,
  });
}

/**
 * @description: 参与记录
 * @param {
 *  page,size,memberId,winning...
 * }
 */
export function getRecord(params) {
  return http.get(
    productService + "overlordMeal/mine/participateRecord/findPage",
    params
  );
}
