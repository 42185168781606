import fetch from '@/plugin/axios'

const state = {
  components: [],
  banner: [],
  quickEntry: [],
  product: {
    list: [],
    total: 0,
    page: 1,
    isMore: true,
    loading: false
  }
}
const getters = {
  components: state => state.components,
  banner: state => state.banner,
  quickEntry: state => state.quickEntry,
  classify: state => {
    return state.quickEntry.filter(item => !item.linkUrl)
  },
  product: state => state.product
}
const mutations = {
  update: (state, { key, value }) => (state[key] = value),
  updateProductLoading: (state, value) => {
    state.product.loading = value
  },
  updateProduct: (state, res) => {
    // if (res.page === 1) {
    //   state.product.list = {
    //     left: [],
    //     right: []
    //   }
    //   state.product.total = 0
    // }
    // for (let i = 0; i < res.list.length; i++) {
    //   state.product.total += 1
    //   if (state.product.total % 2 === 0) {
    //     state.product.list.right.push(res.list[i])
    //   } else {
    //     state.product.list.left.push(res.list[i])
    //   }
    // }
    state.product.list = [...state.product.list, ...res.list]
    state.product.page = res.page
    state.product.isMore = res.page * res.size < res.total
    state.product.loading = false
  }
}
const actions = {
  refresh({ dispatch }) {
    return new Promise((resolve, reject) => {
      Promise.all([dispatch('getBanner'), dispatch('getQuickEntry'), dispatch('getProduct'), dispatch('getModule')])
        .then(() => resolve())
        .catch(err => reject(err))
    })
  },
  getModule(store) {
    return fetch.get('/boom-center-config-service/app/clientAndAreaConfiguration/findConfigByAdminParam', {
      params: {
        configSet: 'homePageConfig',
        client: 'mall-h5',
        // client: 'mall-miniapp',
        areaId: store.getters['common/sys/currentAreaId'] || localStorage.areaId || -1
      }
    }).then(res => {
      res.content.map(item => {
        let _t = item.component.split('_')
        item.component = _t[0]
        item.type = _t[1]
      })
      console.log(res.content,222222)
      store.commit('update', { key: 'components', value: [] })
      setTimeout(() => {
        store.commit('update', { key: 'components', value: res.content?.filter(item => item?.data?.hideStatus != 1) })
      }, 0)
    })
    // return fetch.get('/boom-center-admin-service/app/decoration/module').then(res => {
    //   let data = JSON.parse(res.moduleData)

    //   let top = []
    //   let bottom = []
    //   let classifyIndex = data.findIndex(item => item.componentName === 'areaAndClassify')
    //   let waterfallIndex = 0
    //   classifyIndex = classifyIndex >= 0 ? classifyIndex : data.length
    //   data.map((item, index) => {
    //     item.key = item.componentName + '-' + index
    //     if (item.componentName.includes('waterfall')) {
    //       item.index = waterfallIndex++
    //     }
    //     if (item.componentName.includes('cube') || item.componentName.includes('waterfall')) {
    //       if (item.activeStatus === false) return
    //       let name = item.componentName.split('_')[0]
    //       let type = item.componentName.split('_')[1]
    //       item.componentName = name
    //       item.type = type

    //       if (index < classifyIndex) {
    //         top.push(item)
    //       } else {
    //         bottom.push(item)
    //       }
    //     }
    //   })
    //   store.commit('update', { key: 'components', value: [] })
    //   setTimeout(() => {
    //     store.commit('update', { key: 'components', value: [...top, ...bottom] })
    //   }, 0)
    // })
  },
  getBanner(store) {
    return new Promise((resolve, reject) => {
      fetch
        .get(`/boom-mix-biz-service/app/decoration/banner`)
        .then(res => {
          store.commit('update', { key: 'banner', value: res })
          resolve()
        })
        .catch(() => {
          reject()
        })
    })
  },
  getQuickEntry(store) {
    return new Promise((resolve, reject) => {
      // fetch
      //   .get(`/boom-mix-biz-service/app/decoration/zone`)
      Promise.resolve(store.state.components?.find(item => item.component == "classify")?.data || [])
        .then(res => {
          res.push({
            bannerImageUrl:
              'https://bm-mall-file.oss-cn-shenzhen.aliyuncs.com/miniapp/h5/9c11d2b5e656eaa34cae616fa910f57.png',
            categoryId: '1539789870191353858',
            categoryName: '',
            hideStatus: 0,
            id: '1539789870191353858',
            linkUrl: '',
            productId: 0,
            type: 0,
            typeImageUrl:
              'https://bm-mall-file.oss-cn-shenzhen.aliyuncs.com/miniapp/h5/90aaac94becbb115d712c6c44028ecf.jpg',
            zoneName: '洗浴/spa'
          })
          res.push({
            bannerImageUrl: 'https://file.cdn.tanchi.shop/prod/1688/system/80d5469f69e30bb416939c3e227fa5c0',
            categoryName: '',
            hideStatus: 0,
            id: '1539789870191353858w',
            linkUrl: '/recharge/index',
            productId: 0,
            type: 0,
            zoneName: '手机充值'
          })
          res = res.filter(item => item.type == 0)
          store.commit('update', { key: 'quickEntry', value: res })
          resolve(res)
        })
        .catch(() => {
          reject()
        })
    })
  },
  getProduct(store, page = 1) {
    return new Promise(async (resolve, reject) => {
      if (page === 1) {
        try {
          if (!localStorage['areaId'] || localStorage['areaId'] == 'undefined') {
            return
          }
        } catch {
          reject()
        }
      }
      store.commit('updateProductLoading', true)
      fetch
        .get(`/boom-center-search-service/product/homePageListV1?page=${page}&size=10`)
        .then(res => {
          store.commit('updateProduct', res)
          resolve()
        })
        .catch(() => {
          reject()
        }).finally(() => {
          store.commit('updateProductLoading', false)
        })
    })
  }
}
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
