<template>
  <div class="set-meal">
    <div
      class="sku-item"
      :style="customStyle"
      v-if="data && data.detailPartList && data.detailPartList.length"
    >
      <div class="title">套餐详情</div>
      <template>
        <template class="" v-for="setMealItem in data.detailPartList">
          <div class="setmeal-item">
            <div class="setmeal-name">
              {{ setMealItem.name }}
            </div>
            <div class="item" v-for="item in setMealItem.itemList">
              <div class="name">
                {{ item.name }}
                <!-- <FlodText :text="item.name" /> -->
              </div>
              <div class="num">
                <block wx:if="{{data.symbolDisplayStatus}}">
                  {{ symbol[data.symbol].left }}
                </block>
                {{ item.num }}{{ item.unit || '份' }}
                <block wx:if="{{data.symbolDisplayStatus}}">
                  {{ symbol[data.symbol].right }}
                </block>
              </div>
              <div class="price">￥{{ item.price }}</div>
            </div>
          </div>
        </template>
      </template>
    </div>
  </div>
</template>
<script>
import FlodText from '@/component/common/foldText.vue'
export default {
  props: {
    data: {
      type: Object
    },
    customStyle: String
  },
  data() {
    return {
      symbol: {
        0: {
          left: '(',
          right: ')'
        },
        1: {
          left: '[',
          right: ']'
        },
        2: {
          left: '【',
          right: '】'
        },
        3: {
          left: '{',
          right: '}'
        },
        4: {
          left: '"',
          right: '"'
        }
      }
    }
  }
}
</script>
<style lang="less" scoped>
.set-meal {
  .sku-item {
    margin: 15px;
    background-color: #fff;
    padding: 15px;
    // width: calc(100vw - 40rpx) !important;
    overflow: hidden;
    border-radius: 10px;

    .title {
      font-size: 17px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #343434;
      margin-bottom: 15px;
      word-break: break-all;
      white-space: normal;
    }

    .setmeal-item {
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }

      .setmeal-name {
        font-size: 14px;
        color: #000;
        position: relative;
        font-weight: bold;
        padding: 5px 0;
        padding-left: 10px;
        word-break: break-all;
        white-space: normal;

        &::before {
          content: '';
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 3px;
          height: 3px;
          background-color: #000;
          border-radius: 50%;
        }
      }

      .item {
        display: flex;
        align-items: center;
        padding: 5px 0;
        padding-left: 10px;

        .name {
          flex: 1;
          overflow: hidden;
          word-break: break-all;
          white-space: normal;
          font-size: 14px;
          color: #333;
        }

        .num {
          font-size: 12px;
          color: #999;
          margin-right: 15px;
          margin-left: 7px;
        }

        .price {
          font-size: 14px;
          color: #444;
          font-weight: bold;
        }
      }
    }
  }
}
</style>
