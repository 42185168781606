import http from '@/plugin/axios'

const searchService = 'boom-center-search-service/'
const mixService = 'boom-mix-biz-service/app/'
const productService = 'boom-center-product-service/'
/**
 * @description: 根据分类id获取商品列表
 * @param
 * {categoryId, sortType,page,size},
 * {businessDistrictId,latlon,page,productCategoryId,size}
 */
export function getProductByCatelog(params) {
  if (params.searchType === 3) {
    return http.get(searchService + 'product/category/distance', { params })
  }
  return http.get(searchService + `product/category`, { params })
}
export function getProductByCategroy(params) {
  return http.get(searchService + `product/categoryV1`, { params })
}

//搜索商品或门店
export function search({
  type = 'product',
  businessDistrictIds = [],
  keyword = '',
  location,
  page = 1,
  size = 20
}) {
  if (keyword === '') return Promise.reject('关键字不能为空')
  if (type === 'product') {
    return http.get(searchService + `product?productName=${keyword}&page=${page}&size=${size}`)
  } else {
    console.log(location)
    let params = {
      search: keyword,
      businessDistrictIds,
      location,
      page,
      size,
      sortType: 2
    }
    return http.post(searchService + 'store/search', params)
  }
}

/**
 * @description: 搜索门店
 * @param {businessCategoryIds,businessDistrictIds,distance,location:{lat,lon},orderType,search,page,size }
 */
export function searchStore(params) {
  if (!params.distance) delete params.distance
  return http.post(searchService + 'store/search', params)
}

//获取商家下的门店
export function getStoreByShop({ businessId, lat, lon, page = 1, size = 10 }) {
  return http.get(
    searchService +
      `store/search/business?businessId=${businessId}&lat=${lat}&lon=${lon}&page=${page}&size=${size}`,
    { isAreaLess: true }
  )
}

//根据商品id查询门店
export function getStoreByProduct({ pid, lat, lon, page = 1, size = 10,storeName='' }) {
  return http.get(
    searchService +
      `store/search/product?productId=${pid}&lat=${lat}&lon=${lon}&page=${page}&size=${size}&storeName=${storeName}`,
    { isAreaLess: true }
  )
}

//根据商品id/地区/店名查询门店
export function getStoreByProductV2({
  productId,
  lat = 0,
  lon = 0,
  page = 1,
  size = 10,
  areaId,
  search
}) {
  return http.get(
    searchService +
      `store/product/page?productId=${productId}&lat=${lat}&lon=${lon}&page=${page}&size=${size}&search=${search}`,
    {
      headers: { 'Bm-Area-Id': areaId }
    }
  )
}
/**
 * @description: 获取门店详情
 * @param {门店id,{lat,lon}}
 */
export function getStoreDetail(id) {
  return http.get(searchService + `store/detail/${id}`, { isAreaLess: true })
}

//获取商圈商品
export function getProductByDistrict({ did, page = 1, size = 10 }) {
  return http.get(
    searchService +
      `product/regionBusinessDistrict?businessDistrictId=${did}&page=${page}&size=${size}`
  )
}

export function getStoreReserveInfo({ lat, lon, productId, storeId }) {
  return http.get(
    searchService +
      `store/search/storeId?lat=${lat}&lon=${lon}&productId=${productId}&storeId=${storeId}`
  )
}

//首页商品流
export function homeProductList({ page = 1, size = 10, bid }) {
  return http.get(searchService + `product/homePageListV1?page=${page}&size=${size}`)
}

/**
 * @description: 分享赚列表
 * @param {
 * page,
 * sortField:1=价格,2=最新,3=销量,4=收益,
 * sortType:1=升序,2=降序
 * searchValue
 * }
 */
export function getShareEarn(params) {
  return http.get(searchService + '/product/shareEarnList', params)
}

export function getClassifyChild(parentId) {
  return http.get(productService + `productCategory/${parentId}/withChild`)
}
