import fetch from '@/plugin/axios'
import { formatDate } from '@/util/filter'
import { Dialog } from 'vant'

const state = {
  list: [], //核销码列表
  total: 0, //有效核销码数量
  productDetail: {},
  productDesc: '', //商品-购买须知
  selfCheck: 0 //自助核销-index
}
const getters = {
  list: state => state.list,
  total: state => state.total,
  productDetail: state => state.productDetail,
  productDesc: state => state.productDesc,
  selfCheckCode: state => state.list[state.selfCheck]
}
const mutations = {
  update: (state, { key, value }) => (state[key] = value)
}
const actions = {
  async getCodeList(store, orderId) {
    let res = await fetch.get(
      '/boom-mix-biz-service/app/orderCheckCode/page/listMyOrderCheckCode',
      {
        params: {
          orderId,
          size: 50
        },
        isAreaLess: true
      }
    )
    let total = 0
    let valid = []
    let invalid = []
    res.list.map(item => {
      if (item.refundStatus === 0 && item.checkStatus === 1) {
        valid.push(item)
        total++
      } else {
        invalid.push(item)
      }
    })
    if (parseInt(res.list[0].checkOffTimeStart) > new Date().getTime()) {
      res.checkOffTimeStart = formatDate(parseInt(res.list[0].checkOffTimeStart))
      res.checkOffTimeEnd = formatDate(parseInt(res.list[0].checkOffTimeEnd))
      Dialog({
        title: '该商品未到核销时间',
        message: `${res.checkOffTimeStart}~${res.checkOffTimeEnd}期间可核销`,
        showCancelButton: false
      }).then(() => this.$router.back())
    }
    let product = await fetch.get(
      `/boom-center-product-service/product/${res.list[0].productId}/v2`,
      {
        isAreaLess: true
      }
    )
    product.skuDetailList = product.skuDetailList.filter(
      item => item.detailPartList?.length && (item.displayStatus || item.id == 0)
    )

    store.commit('update', {
      key: 'list',
      value: [...valid, ...invalid]
    })
    store.commit('update', {
      key: 'total',
      value: total
    })
    store.commit('update', {
      key: 'productDetail',
      value: product
    })
    store.commit('update', {
      key: 'productDesc',
      value: product.purchaseInstructions
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
